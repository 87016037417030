import React from 'react'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
import Button  from '@mui/material/Button';


function ExcelExport({excelData, fileName}) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () =>{
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type:'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension)
    }
  return (
    <>
        <Button variant='contained' onClick={(e) =>
            exportToExcel(fileName)
        }
        color='success'
        size='small'
        >
            <i class="fa-solid fa-file-excel"></i> &nbsp;Download Excel File
        </Button>
    </>
  )
}

export default ExcelExport