import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const SchemesApi = {
  GetByFilters: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.schemes.filter, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        periodFrom: payload.periodFrom,
        periodTo: payload.periodTo,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  SearchByKeyword: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.schemes.search, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        periodFrom: payload.periodFrom,
        periodTo: payload.periodTo,
        keyword: payload.keyword,
        userGroups: payload.userGroups,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  AddScheme: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.schemes.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdateScheme: async (payload) => {
    const response = await axios.put(
      stage_url + sub_urls.schemes.baseUrl + "/" + payload.id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  SearchByGroup: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.group.search, {
      params: {
        page: payload.page,
        size: payload.size,
        keyword: payload.keyword,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
};
