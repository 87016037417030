import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const NlcApi = {
  GetNlcListByFilter: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.nlc.filter, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        periodFrom: payload.periodFrom,
        periodTo: payload.periodTo,
        sortBy: "product.primaryName",
        direction: "ASC",
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  GetNlcV2ListByFilter: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.nlc.filterV2, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        periodFrom: payload.periodFrom,
        periodTo: payload.periodTo,
        sortBy: "product.primaryName",
        direction: "ASC",
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  GetNlcByProduct: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.nlc.byProductId, {
      params: {
        page: payload.page,
        size: payload.size,
        productId: payload.productId,
        userGroups: payload.userGroups,
        periodTo: payload.periodTo,
        periodFrom: payload.periodFrom,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  SearchNlc: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.nlc.search, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        periodFrom: payload.periodFrom,
        periodTo: payload.periodTo,
        keyword: payload.keyword,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  AddNlcMultiple: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.nlc.multipleAdd,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddNlc: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.nlc.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdateNlc: async (payload) => {
    const response = await axios.put(
      stage_url + sub_urls.nlc.baseUrl + "/" + payload.nlcId,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  // AddVariant: async (id, payload) => {
  //     const response = await axios.post(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + id + '/variant', payload)
  //     return response;
  // },
  // AddColor: async (id, payload) => {
  //     const response = await axios.post(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + id + '/color', payload)
  //     return response;
  // },
  // AddPrice: async (id, payload) => {
  //     const response = await axios.post(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + id + '/price', payload)
  //     return response;
  // },
  // EditProduct: async (id, payload) => {
  //     const response = await axios.put(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + id, payload)
  //     return response;
  // },
  // EditVariant: async (pid, id, payload) => {
  //     const response = await axios.put(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + pid + '/variant/' + id, payload)
  //     return response;
  // },
  // EditColor: async (pid, id, payload) => {
  //     const response = await axios.put(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + pid + '/color/' + id, payload)
  //     return response;
  // },
  // EditPrice: async (pid, id, payload) => {
  //     const response = await axios.put(
  //         stage_url + sub_urls.products.productBaseUrl + '/' + pid + '/price/' + id, payload)
  //     return response;
  // },
  // GetCategoryList: async (payload) => {
  //     const response = await axios.get(
  //         stage_url + sub_urls.category.getByFilter, {
  //         params: {
  //             page: payload.page,
  //             size: payload.size
  //         }
  //     })
  //     return response;
  // },
};
