import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const GroupsApi = {
  AddGroup: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.group.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetGroupsListByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.group.getGroupsByFilter,
      {
        params: {
          type: payload.type,
          page: payload.page,
          size: payload.size,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetGroupsListById: async (id) => {
    const response = await axios.get(
      stage_url + sub_urls.group.baseUrl + "/" + id,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditGroup: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.group.baseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetGroupsListBySearch: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.group.getGroupsBySearch,
      {
        params: {
          keyword: payload.keyword,
          page: payload.page,
          size: payload.size,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
