import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const UsersSubscriptionsApi = {
  GetUserSubscriptions: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.usersSubscriptions.getUserSubscriptionsByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
          "user.mobile": payload.mobile,
          "user.id": payload.userId,
          isDeleted: false,
          isActive: payload.isActive,
          startDate: payload.startDate,
          endDate: payload.endDate,
          sortBy: payload.sortBy,
          price: payload.price,
          invoiceNo: payload.invoiceNo,
          direction: payload.direction,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  SearchUserSubscriptions: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.usersSubscriptions.getUserSubscriptionsByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
          mobile: payload.mobile,
          isActive: payload.isActive,
          startDate: payload.startDate,
          endDate: payload.endDate,
          sortBy: payload.sortBy,
          price: payload.price,
          invoiceNo: payload.invoiceNo,
          direction: payload.direction,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  CreateSubscription: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.subscription.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdateSubscription: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.subscription.baseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetSubscription: async () => {
    const response = await axios.get(
      stage_url + sub_urls.subscription.baseUrl,
      {
        params: {
          type: "ALL",
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddUserSubscription: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.usersSubscriptions.baseUrl,
      null,
      {
        params: {
          userId: payload.userId,
          subscriptionId: payload.subscriptionId,
          startDate: payload.startDate,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditUserSubscription: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.usersSubscriptions.baseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  Invoice: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.usersSubscriptions.invoice + "/" + payload.id,
      {
        params: {
          id: payload.id,
          invoiceNo: payload.invoiceNo,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
