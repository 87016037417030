import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { UsersSubscriptionsApi } from "apis/UsersSubscriptionsApi";
import { useNavigate } from "react-router";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ExcelExport from "components/ExcelExport/ExcelExport";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import { formatDate } from "utils";
import { UsersApi } from "apis/UsersApi";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

function Subscriptions() {
  const navigate = useNavigate();
  const color = "light";
  const [open, setOpen] = React.useState(false);
  const [openGenerateInvoice, setOpenGenerateInvoice] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [usersSubscriptions, setUsersSubscriptions] = useState([]);
  const [filteredUsersSubscriptions, setfilteredUserSubscriptions] = useState(
    []
  );
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    mobile: "",
    isActive: true,
    price: "",
    startDate: "",
    endDate: "",
  });

  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;
  let fileName = `UserSubscriptionData_${date.getDate()}${
    date.getMonth() + 1
  }${date.getFullYear()}_${date.getHours()}${date.getMinutes()}`;
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [mobileNo, setMobileNo] = useState("");
  const [totalElements, setTotalElements] = useState("");
  const startDateInput = dayjs(formData.startDate);
  const endDateInput = dayjs(formData.endDate);
  const [price, setPrice] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [subscription, setSubscription] = useState([]);
  const [editSubscription, setEditSubscription] = useState(null);

  const getSubscription = () => {
    UsersSubscriptionsApi.GetSubscription()
      .then((res) => {
        setSubscription(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseGenerateInvoice = () => {
    setOpenGenerateInvoice(false);
  };

  useEffect(() => {
    getSubscription();
    const filtered = usersSubscriptions.filter((subscription) =>
      subscription.user.mobile.includes(searchTerm)
    );
    setfilteredUserSubscriptions(filtered);
  }, [searchTerm, usersSubscriptions]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleGenerateInvoice = (row) => {
    setOpenGenerateInvoice(true);
    setInvoice({ ...invoice, id: row.id });
  };
  const GenerateInvoice = () => {
    UsersSubscriptionsApi.Invoice({
      id: invoice.id,
      invoiceNo: invoice.invoiceNo,
    })
      .then((res) => {
        handleCloseGenerateInvoice();
        GetUserSubscriptions(currentPage - 1, 25);
        toast("Invoice Generated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        toast(err.response.status + " " + err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };
  const [invoice, setInvoice] = useState({
    id: null,
    invoiceNo: null,
  });
  const handleChangeDate = (name, value) => {
    let date = new Date(value);
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    const selectedDate = [date.getFullYear(), mnth, day].join("-");
    // console.log(selectedDate);
    setFormData({
      ...formData,
      [name]: selectedDate,
    });
  };

  const [filterPayload, setFilterPayload] = useState({
    isActive: null,
    startDate: null,
    endDate: null,
    sortBy: null,
    price: null,
    invoiceNo: null,
    direction: null,
    mobile: null,
  });
  const clearSearch = () => {
    setFilterPayload({
      isActive: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      price: null,
      invoiceNo: null,
      direction: null,
      mobile: null,
    });
    setInvoiceNo("");
    setPrice("");
    setMobileNo("");
    GetUserSubscriptions(0, 25);
    setCurrentPage(1);
  };

  const [userSize, setUserSize] = useState(25);
  const [openExcelDialog, setOpenExcelDialog] = React.useState(false);
  const handleClickOpenExcelDialog = () => {
    setOpenExcelDialog(true);
  };
  const handleCloseExcelDialog = () => {
    setOpenExcelDialog(false);
  };
  const [excelData, setExcelData] = useState([]);
  const GetUserSubscriptions = (page, size) => {
    UsersSubscriptionsApi.GetUserSubscriptions({
      page: page,
      size: size,
      mobile: filterPayload.mobile,
      isActive: filterPayload.isActive,
      startDate: filterPayload.startDate,
      endDate: filterPayload.endDate,
      sortBy: filterPayload.sortBy,
      price: filterPayload.price,
      invoiceNo: filterPayload.invoiceNo,
      direction: filterPayload.direction,
    })
      .then((res) => {
        setUsersSubscriptions(res.data.data);
        setTotalElements(res.data.totalElements);
        setTotalPages(res.data.pages);
        setfilteredUserSubscriptions(usersSubscriptions);
        const excelDataArray = res.data.data.map((m) => ({
          name: m?.user?.name,
          mobile: m?.user?.mobile,
          email: m?.user?.email,
          invoiceNumber: m?.invoiceNo,
          price: m?.price,
          isActive: m?.isActive,
          startDate: m?.startDate,
          endDate: m?.endDate,
        }));
        setExcelData(excelDataArray);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const EditUserSubscription = (row) => {
    setIsEdit(true);
    setFormData({
      id: row.id,
      name: row.user.name,
      email: row.user.email,
      mobile: row.user.mobile,
      isActive: row.isActive,
      price: row.price,
      startDate: row.startDate,
      endDate: row.endDate,
    });
    setOpen(true);
  };

  const EditSubscription = (row) => {
    setIsEdit(true);
    setFormData({
      id: row.id,
      name: row.user.name,
      email: row.user.email,
      mobile: row.user.mobile,
      isActive: row.isActive,
      price: row.price,
      startDate: row.startDate,
      endDate: row.endDate,
    });
    setOpen(true);
  };
  const edit = () => {
    UsersSubscriptionsApi.EditUserSubscription(formData.id, formData)
      .then((res) => {
        toast("Subscription Updated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        GetUserSubscriptions(0, 25);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    GetUserSubscriptions(page - 1, 25);
    return currentPage;
  };

  useEffect(() => {
    GetUserSubscriptions(0, 25);
  }, [filterPayload]);

  useEffect(() => {
    GetUserSubscriptions(0, 25);
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Grid container spacing={2}>
            <Grid xs={10}>
              <Grid container>
                <TextField
                  fullWidth
                  label="Search by Mobile number"
                  id="fullWidth"
                  sx={{ width: "85%" }}
                  value={mobileNo}
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                    if (e.target.value?.length === 10) {
                      setFilterPayload({
                        ...filterPayload,
                        mobile: e.target.value,
                      });
                    } else if (e.target.value?.length === 0) {
                      setFilterPayload({
                        ...filterPayload,
                        mobile: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                {/* <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Chip label="General Filters" />
            </Grid> */}
                <Grid sx={{ width: "25%" }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFilterPayload({
                        ...filterPayload,
                        startDate: e.target?.value,
                      });
                    }}
                    value={filterPayload?.startDate}
                    fullWidth
                    id="outlined-basic"
                    helperText="Start Date"
                    variant="outlined"
                  />
                </Grid>
                <Grid sx={{ width: "25%" }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFilterPayload({
                        ...filterPayload,
                        endDate: e.target?.value,
                      });
                    }}
                    value={filterPayload?.endDate}
                    fullWidth
                    id="outlined-basic"
                    helperText="End Date"
                    variant="outlined"
                  />
                </Grid>
                <Grid>
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Is Active
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group"
                      name="Is Active"
                      value={filterPayload?.isActive}
                      onChange={(e) => {
                        setFilterPayload({
                          ...filterPayload,
                          isActive: e.target?.value,
                        });
                      }}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"True"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={"False"}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <FormControl sx={{ width: "68%" }}>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Sort By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterPayload.sortBy}
                      label="Sort By"
                      onChange={(e) => {
                        setFilterPayload({
                          ...filterPayload,
                          sortBy: e.target.value,
                        });
                      }}>
                      <MenuItem value={null}>--Sort By--</MenuItem>
                      <MenuItem value={"price"}>Price</MenuItem>
                      <MenuItem value={"startDate"}>Start Date</MenuItem>
                      <MenuItem value={"endDate"}>End Date</MenuItem>
                      <MenuItem value={"isActive"}>Is Active</MenuItem>
                      <MenuItem value={"invoiceNo"}>Invoice Number</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid sx={{ width: "25%" }}>
                  <TextField
                    id="outlined-basic"
                    // sx={{ marginY:2}}
                    label="Price"
                    type="number"
                    name="price"
                    variant="outlined"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setFilterPayload({
                        ...filterPayload,
                        price: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid sx={{ width: "25%" }}>
                  <TextField
                    id="outlined-basic"
                    // sx={{ marginY:2}}
                    label="Invoice Number"
                    name="InvoiceNo"
                    variant="outlined"
                    value={invoiceNo}
                    onChange={(e) => {
                      setInvoiceNo(e.target.value);
                      setFilterPayload({
                        ...filterPayload,
                        invoiceNo: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid sx={{ width: "50%" }}>
                  <FormControl sx={{ width: "48%" }}>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Direction
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterPayload.direction}
                      label="Direction"
                      onChange={(e) => {
                        setFilterPayload({
                          ...filterPayload,
                          direction: e.target.value,
                        });
                      }}>
                      <MenuItem value={null}>--Select Direction--</MenuItem>
                      <MenuItem value={"ASC"}>Ascending: Low to High</MenuItem>
                      <MenuItem value={"DESC"}>
                        Descending: High to Low
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}>
                  <Grid sx={{ width: "13%" }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      onClick={clearSearch}>
                      Reset All
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enter Details</DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid xs={6} md={6}>
                      <TextField
                        id="outlined-basic"
                        sx={{ marginY: 2 }}
                        label="Name"
                        name="name"
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <TextField
                        id="outlined-basic"
                        type="number"
                        label="Mobile number"
                        name="mobile"
                        variant="outlined"
                        sx={{ marginY: 2 }}
                        value={formData.mobile}
                        onChange={handleChange}
                      />

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ marginY: 2 }}
                          components={["DatePicker"]}>
                          <DatePicker
                            name="startDate"
                            label="Start Date"
                            value={startDateInput}
                            onChange={(date) =>
                              handleChangeDate("startDate", date)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          sx={{ marginY: 2 }}
                          components={["DatePicker"]}>
                          <DatePicker
                            name="endDate"
                            label="End Date"
                            value={endDateInput}
                            onChange={(date) =>
                              handleChangeDate("endDate", date)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={6} md={6}>
                      <TextField
                        id="outlined-basic"
                        type="email"
                        label="Email"
                        name="email"
                        variant="outlined"
                        sx={{ marginY: 2 }}
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <FormControl sx={{ marginY: 2 }}>
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          IsActive
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group"
                          name="isActive"
                          value={formData.isActive}
                          onChange={handleChange}>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="True"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="False"
                          />
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        label="Price"
                        name="price"
                        variant="outlined"
                        sx={{ marginY: 2 }}
                        value={formData.price}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={edit}>Save Changes</Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid xs={2}>
              <div className="grid grid-cols-3 gap-4 content-center">
                <CreateSubscription
                  row={editSubscription}
                  setRow={setEditSubscription}
                  GetUserSubscriptions={GetUserSubscriptions}
                />
                <br />
                <AddUserSubscription subscription={subscription} />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="text-center p-2 mb-2"></div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Validity" />
                <TableHeader heading="Price" />
                <TableHeader heading="Disc Price" />
                <TableHeader heading="Logins" />
                <TableHeader heading="Message" />
                <TableHeader heading="Active" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {subscription?.map((s, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />
                    <TableElement value={<>{s.name}</>} />
                    <TableElement value={s.validity} />
                    <TableElement value={s.price} />
                    <TableElement value={s.discountedPrice} />
                    <TableElement value={s.logins} />
                    <TableElement value={s.message} />
                    <TableElement value={s.isActive} type="boolean" />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => setEditSubscription(s)}>
                          EDIT
                        </span>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="w-full flex justify-end p-2 text-sky-500">
            Total: {totalElements}
          </div>
          <div className="text-center p-2 mb-2">
            <div className="">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
            <div className="text-left ml-3 mt-2">
              <Button
                variant="contained"
                color="success"
                onClick={handleClickOpenExcelDialog}>
                Export Data
              </Button>
              <Dialog
                open={openExcelDialog}
                onClose={handleCloseExcelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  Export Subscription Data
                </DialogTitle>
                <DialogContent>
                  You are Downloading subscription data of{" "}
                  <TextField
                    id="filled-basic"
                    onChange={(e) => {
                      GetUserSubscriptions(0, e.target.value);
                      setUserSize(e.target.value);
                    }}
                    variant="standard"
                    sx={{ width: "60px" }}
                    value={userSize}
                  />{" "}
                  users
                </DialogContent>
                <DialogActions>
                  <ExcelExport excelData={excelData} fileName={fileName} />
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="User" />
                <TableHeader heading="Invoice Number" />
                <TableHeader heading="Invoice" />
                <TableHeader heading="Active" />
                <TableHeader heading="Price" />
                <TableHeader heading="Start Date" />
                <TableHeader heading="End Date" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {usersSubscriptions.map((u, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />
                    <TableElement
                      value={
                        <>
                          {u.user?.name}
                          <br />
                          {u.user?.mobile}
                          <br />
                          {u.user?.email}
                        </>
                      }
                    />
                    <TableElement value={u.invoiceNo ? u.invoiceNo : "-"} />
                    <TableElement
                      value={
                        u.invoiceUrl ? (
                          <>
                            <a href={u.invoiceUrl} target="__blank">
                              <Button
                                variant="text"
                                aria-describedby="showInvoice-popover">
                                Show
                              </Button>
                            </a>
                          </>
                        ) : (
                          <Button
                            variant="text"
                            onClick={handleGenerateInvoice}
                            aria-describedby="url-popover">
                            Generate
                          </Button>
                        )
                      }
                    />
                    <TableElement value={u.isActive} type="boolean" />
                    <TableElement value={u.price} />
                    <TableElement value={formatDate(u.startDate)} />
                    <TableElement value={formatDate(u.endDate)} />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => EditUserSubscription(u)}>
                          EDIT
                        </span>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* GENERATE INVOICE DIALOG  */}
      <Dialog open={openGenerateInvoice} onClose={handleCloseGenerateInvoice}>
        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="invoiceNo"
            label="Enter Invoice Number"
            type="text"
            onChange={(e) => {
              setInvoice({ ...invoice, invoiceNo: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={GenerateInvoice}>Generate</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </>
  );
}

const CreateSubscription = ({ GetUserSubscriptions, row, setRow }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [addSubscriptionData, setAddSubscriptionData] = useState({
    name: null,
    validity: null,
    price: null,
    discountedPrice: null,
    message: null,
    displayOnApp: null,
    isActive: null,
    isTrial: null,
    logins: null,
    isDeleted: null,
  });

  useEffect(() => {
    if (row) {
      setAddSubscriptionData({
        name: row.name,
        validity: row.validity,
        price: row.price,
        discountedPrice: row.discountedPrice,
        message: row.message,
        displayOnApp: row.displayOnApp,
        isActive: row.isActive,
        isTrial: row.isTrial,
        isDeleted: row.isDeleted,
        logins: row.logins,
      });
    }
  }, [row]);

  const createUpdateSubscription = () => {
    if (row) {
      UsersSubscriptionsApi.UpdateSubscription(row.id, addSubscriptionData)
        .then((res) => {
          toast("Subscripton Updated", {
            type: "success",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setOpen(false);
          setRow(null);
          GetUserSubscriptions(0, 25);
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast(err.response?.status + " " + err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          } else {
            toast(err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
          if (err.response?.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    } else {
      UsersSubscriptionsApi.CreateSubscription(addSubscriptionData)
        .then((res) => {
          toast("Subscripton Created", {
            type: "success",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setOpen(false);
          GetUserSubscriptions(0, 25);
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast(err.response?.status + " " + err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          } else {
            toast(err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
          if (err.response?.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="">
          <button
            onClick={() => {
              setOpen(true);
              setAddSubscriptionData({
                name: null,
                validity: null,
                price: null,
                discountedPrice: null,
                message: null,
                displayOnApp: null,
                isActive: null,
                isTrial: null,
                isDeleted: null,
                logins: null,
              });
            }}
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            <span className="mr-2">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            Create Subscription
          </button>
        </div>
      </div>

      {/* Add Subscription */}
      <div>
        <Dialog
          fullScreen
          open={row ? true : open}
          onClose={() => {
            setRow(null);
            setOpen(false);
          }}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setRow(null);
                  setOpen(false);
                }}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {row
                  ? "Edit  Subscription - " + row.name
                  : "Create Subscription"}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  sx={{ marginY: 2 }}
                  label="Name"
                  variant="standard"
                  value={addSubscriptionData.name}
                  onChange={(e) => {
                    setAddSubscriptionData({
                      ...addSubscriptionData,
                      name: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="number"
                  helperText="Numerical value only"
                  sx={{ marginY: 2 }}
                  label="Validity"
                  variant="standard"
                  value={addSubscriptionData.validity}
                  onChange={(e) => {
                    setAddSubscriptionData({
                      ...addSubscriptionData,
                      validity: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="number"
                  helperText="Numerical value only"
                  sx={{ marginY: 2 }}
                  label="Price"
                  variant="standard"
                  value={addSubscriptionData.price}
                  onChange={(e) => {
                    setAddSubscriptionData({
                      ...addSubscriptionData,
                      price: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="number"
                  helperText="Numerical value only"
                  sx={{ marginY: 2 }}
                  label="Discounted Price"
                  variant="standard"
                  value={addSubscriptionData.discountedPrice}
                  onChange={(e) => {
                    setAddSubscriptionData({
                      ...addSubscriptionData,
                      discountedPrice: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  sx={{ marginY: 2 }}
                  label="Notification Message"
                  variant="standard"
                  value={addSubscriptionData.message}
                  onChange={(e) => {
                    setAddSubscriptionData({
                      ...addSubscriptionData,
                      message: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="number"
                  sx={{ marginY: 2 }}
                  label="Number of Logins"
                  variant="standard"
                  value={addSubscriptionData.logins}
                  onChange={(e) => {
                    setAddSubscriptionData({
                      ...addSubscriptionData,
                      logins: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ marginY: 2, minWidth: "100%" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Display On App
                  </FormLabel>
                  <RadioGroup
                    value={addSubscriptionData.displayOnApp}
                    row
                    onChange={(e) => {
                      setAddSubscriptionData({
                        ...addSubscriptionData,
                        displayOnApp: e.target.value,
                      });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="displayOnApp">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ marginY: 2, minWidth: "100%" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Active
                  </FormLabel>
                  <RadioGroup
                    value={addSubscriptionData.isActive}
                    row
                    onChange={(e) => {
                      setAddSubscriptionData({
                        ...addSubscriptionData,
                        isActive: e.target.value,
                      });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isActive">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ marginY: 2, minWidth: "100%" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Trial
                  </FormLabel>
                  <RadioGroup
                    value={addSubscriptionData.isTrial}
                    row
                    onChange={(e) => {
                      setAddSubscriptionData({
                        ...addSubscriptionData,
                        isTrial: e.target.value,
                      });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isTrial">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ marginY: 2, minWidth: "100%" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Deleted
                  </FormLabel>
                  <RadioGroup
                    value={addSubscriptionData.isDeleted}
                    row
                    onChange={(e) => {
                      setAddSubscriptionData({
                        ...addSubscriptionData,
                        isDeleted: e.target.value,
                      });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isDeleted">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <div className="text-center">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                sx={{ m: 4 }}
                onClick={createUpdateSubscription}>
                {row ? "Save Changes" : "Save"}
              </Button>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const AddUserSubscription = ({ subscription }) => {
  const navigate = useNavigate();
  const date = new Date();
  const currentDate =
    date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [verifyUserTick, setVerifyUserTick] = useState(false);

  const [filterPayload, setFilterPayload] = useState({
    userId: null,
    subscriptionId: null,
    startDate: currentDate,
  });

  const getUsers = (mobile) => {
    UsersApi.GetUsersListByFilter({
      mobile: mobile,
      page: 0,
      size: 25,
    })
      .then((res) => {
        if (res.data.pages === 0) {
          toast(`User Not Found with mobile: ${mobile}`, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setUsers(null);
          setVerifyUserTick(false);
        } else {
          setUsers(res.data.data);
          setVerifyUserTick(true);
        }
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const createSubscription = () => {
    UsersSubscriptionsApi.AddUserSubscription(filterPayload)
      .then((res) => {
        toast("Subscripton Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="">
          <button
            onClick={() => {
              setMobile("");
              setUsers(null);
              setFilterPayload({
                userId: null,
                subscriptionId: null,
                startDate: currentDate,
              });
              setOpen(true);
            }}
            className="bg-lightBlue-800 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            <span className="mr-2">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            Add Subscription
          </button>
        </div>
      </div>

      {/* Add Subscription */}
      <div>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Subscription
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4} style={{ padding: "15px" }}>
                <TextField
                  id="standard-requried"
                  required
                  sx={{ marginY: 2 }}
                  label="Search User by Mobile Number"
                  variant="standard"
                  onChange={(e) => {
                    setMobile(e.target.value);
                    if (e.target.value?.length === 10) {
                      setMobile(e.target.value);
                      getUsers(e.target.value);
                    } else if (e.target.value?.length === 0) {
                      setVerifyUserTick(false);
                      setUsers(null);
                      setMobile(null);
                    }
                  }}
                  value={mobile}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: "15px" }}>
                <div className="mt-8">
                  {verifyUserTick ? (
                    <i
                      title="User found"
                      className="fa-solid fa-circle-check fa-lg"
                      style={{ color: "#2ef32b" }}></i>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>
            {users === null ? (
              <></>
            ) : (
              <>
                <div className="mt-4">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr className="divide-x divide-gray-200 ">
                        <TableHeader heading="" />
                        <TableHeader heading="Name" />
                        <TableHeader heading="Mobile" />
                        <TableHeader heading="Start Date" />
                        <TableHeader heading="Select Subscription" />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {users?.map((m, i) => (
                        <tr
                          key={i}
                          style={{ padding: "5px" }}
                          className="divide-x divide-gray-200 hover:bg-slate-100">
                          <TableElement value={i + 1} />
                          <TableElement value={m?.name} />
                          <TableElement value={m?.mobile} />
                          <TableElement
                            value={
                              <>
                                <TextField
                                  type="date"
                                  onChange={(e) =>
                                    setFilterPayload({
                                      ...filterPayload,
                                      startDate: e?.target.value,
                                    })
                                  }
                                  value={filterPayload.startDate}
                                  fullWidth
                                  id="outlined-basic"
                                  label="Start Date"
                                  variant="outlined"
                                />
                              </>
                            }
                          />
                          <TableElement
                            value={
                              <>
                                <div className="p-2">
                                  <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Subscription"
                                    value={filterPayload.subscriptionId}
                                    onChange={(e) => {
                                      setFilterPayload({
                                        ...filterPayload,
                                        subscriptionId: e?.target?.value,
                                        userId: m?.id,
                                      });
                                    }}>
                                    {subscription?.map((m, i) => (
                                      <MenuItem key={i} value={m?.id}>
                                        {m?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              </>
                            }
                          />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="text-right">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    sx={{ m: 4 }}
                    onClick={createSubscription}>
                    Add Subscription
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Subscriptions;
