import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { NotificationTopicsApi } from "apis/NotificationTopicsApi";
import { GroupsApi } from "apis/GroupsApi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";

const NotificationTopics = () => {
  const navigate = useNavigate();
  const color = "light";
  const [notification, setNotification] = useState([]);
  const [groups, setGroups] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: null,
    description: null,
    isActive: null,
    isDeleted: null,
    groups: [],
  });
  const [open, setOpen] = useState(false);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // GET NOTFICATION
  const getNotification = () => {
    NotificationTopicsApi.GetNotification()
      .then((res) => {
        setNotification(res.data.data);
        setTotalPages(res.data.pages);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const addNotification = () => {
    NotificationTopicsApi.AddNotification(formData)
      .then((res) => {
        toast("Notification Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        setFormData({
          name: null,
          description: null,
          isActive: null,
          isDeleted: null,
          groups: []
        });
        getNotification();
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  //   EDIT NOTIFICATION
  const EditNotification = (row) => {
    setIsEdit(true);
    setFormData({
      id: row.id,
      name: row.name,
      description: row.description,
      isActive: row.isActive,
      isDeleted: row.isDeleted,
    });
    setOpen(true);
  };

  const edit = () => {
    NotificationTopicsApi.EditNotification(formData.id, formData)
      .then((res) => {
        toast("Notification Updated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        setFormData({
          name: null,
          description: null,
          isActive: null,
          isDeleted: null,
          groups: []
        });
        getNotification();
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: null,
      description: null,
      isActive: null,
      isDeleted: null,
      groups: []
    });
    setIsEdit(false);
  };

  const getGroupsData = (keyword) => {
    GroupsApi.GetGroupsListBySearch({
      keyword: keyword,
      page: 0,
      size: 99,
    })
      .then((res) => {
        setGroups(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getSelectedGroups = (data) => {
    setFormData({
      ...formData,
      groups: data.map((e) => e.id),
    });
    setGroups(data);
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="text-right">
          <button
            onClick={(e) => setOpen(true)}
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            <span className="mr-2">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            Add Notification
          </button>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-8">
          <div className="text-center border  flex justify-between p-2 mb-2">
            <div className="my-1">
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Description" />
                <TableHeader heading="Active" />
                <TableHeader heading="Deleted" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {notification?.map((n, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement value={n.name} />
                    <TableElement value={n.description} />
                    <TableElement value={n.isActive} type="boolean" />
                    <TableElement value={n.isDeleted} type="boolean" />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => EditNotification(n)}
                        >
                          EDIT
                        </span>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {!isEdit
            ? "Add Notification"
            : `Edit Notification - ${formData.name}`}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xs={6} md={6}>
              <TextField
                id="standard-basic"
                sx={{ m: 2 }}
                label="Name"
                name="name"
                variant="standard"
                value={formData.name}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  });
                }}
              />

              <TextField
                id="standard-basic"
                sx={{ m: 2 }}
                label="Description"
                name="description"
                variant="standard"
                multiline
                value={formData.description}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    description: e.target.value,
                  });
                }}
              />

              <div className="mx-4 mt-7">
                <MatAutoComplete
                label={"Search by Group name"}
                getData={getGroupsData}
                options={groups}
                setOptions={setGroups}
                setValue={getSelectedGroups}
                isMultiple={true}
              /></div>
            </Grid>
            <Grid xs={6} md={6}>
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Active
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isActive"
                  value={formData.isActive}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isActive: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Deleted
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isDeleted"
                  value={formData.isDeleted}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isDeleted: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isEdit ? (
            <Button onClick={edit}>Save Changes</Button>
          ) : (
            <Button onClick={addNotification}>Save</Button>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default NotificationTopics;
