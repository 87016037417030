import React from "react";
import TextField from "@mui/material/TextField";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { Button } from "@mui/material";

const AddSchemesTable = ({ addSchemePayload, setAddSchemePayload }) => {
  const applyValueToAll = (key) => {
    let remark = addSchemePayload.productDTOs[0].remark;
    let amount = addSchemePayload.productDTOs[0].amount;
    let startDate = addSchemePayload.productDTOs[0].startDate;
    let endDate = addSchemePayload.productDTOs[0].endDate;

    let arr = addSchemePayload.productDTOs.map((e) => {
      switch (key) {
        case "remark":
          e.remark = remark;
          break;
        case "amount":
          e.amount = amount;
          break;
        case "startDate":
          e.startDate = startDate;
          break;
        case "endDate":
          e.endDate = endDate;
          break;
        default:
          break;
      }
      return e;
    });

    setAddSchemePayload((prev) => ({ ...prev, productDTOs: arr }));
  };

  return (
    <table className="min-w-full divide-y divide-gray-200 mt-5">
      <thead className="bg-gray-100">
        <tr className="divide-x divide-gray-200 ">
          <TableHeader heading="Serial" />
          <TableHeader heading="Product Name" />
          <TableHeader heading="StartDate" />
          <TableHeader heading="EndDate" />
          <TableHeader heading="Amount" />
          <TableHeader heading="Remark" />
        </tr>
      </thead>
      <tbody>
        {addSchemePayload?.productDTOs?.length && (
          <tr>
            <td></td>
            <td></td>
            <td>
              <Button onClick={() => applyValueToAll("startDate")}>
                Apply to all
              </Button>
            </td>
            <td>
              <Button onClick={() => applyValueToAll("endDate")}>
                Apply to all
              </Button>
            </td>
            <td>
              <Button onClick={() => applyValueToAll("amount")}>
                Apply to all
              </Button>
            </td>
            <td>
              <Button onClick={() => applyValueToAll("remark")}>
                Apply to all
              </Button>
            </td>
          </tr>
        )}

        {addSchemePayload?.productDTOs?.map((model, i) => {
          return (
            <TableRow
              addSchemePayload={addSchemePayload}
              setAddSchemePayload={setAddSchemePayload}
              model={model}
              i={i}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const TableRow = ({ model, i, setAddSchemePayload }) => {
  return (
    <tr key={i + 1} className="divide-x divide-gray-200 hover:bg-slate-100">
      <TableElement value={i + 1} />
      <TableElement value={model.productName} />
      <TableElement
        value={
          <TextField
            id="standard-requried"
            required
            fullWidth
            type="date"
            sx={{ marginY: 2 }}
            label="Start Date"
            variant="standard"
            onChange={(e) => {
              setAddSchemePayload((prev) => {
                const newProductDTOs = prev.productDTOs;
                newProductDTOs[i] = {
                  ...newProductDTOs[i],
                  startDate: e.target.value,
                };
                return { ...prev, productDTOs: newProductDTOs };
              });
            }}
            value={model.startDate}
          />
        }
      />
      <TableElement
        value={
          <TextField
            id="standard-requried"
            required
            fullWidth
            type="date"
            sx={{ marginY: 2 }}
            label="End Date"
            variant="standard"
            onChange={(e) => {
              setAddSchemePayload((prev) => {
                const newProductDTOs = prev.productDTOs;
                newProductDTOs[i] = {
                  ...newProductDTOs[i],
                  endDate: e.target.value,
                };
                return { ...prev, productDTOs: newProductDTOs };
              });
            }}
            value={model.endDate}
          />
        }
      />

      <TableElement
        value={
          <TextField
            key={i + 2}
            fullWidth
            sx={{ marginY: 2 }}
            onChange={(e) => {
              setAddSchemePayload((prev) => {
                const newProductDTOs = prev.productDTOs;
                newProductDTOs[i] = {
                  ...newProductDTOs[i],
                  amount: e.target.value,
                };
                return { ...prev, productDTOs: newProductDTOs };
              });
            }}
            value={model.amount}
            label="Amount"
            id="outlined-basic"
            variant="standard"
          />
        }
      />
      <TableElement
        value={
          <TextField
            key={i + 3}
            fullWidth
            label="Remark"
            sx={{ marginY: 2 }}
            value={model.remark}
            onChange={(e) => {
              setAddSchemePayload((prev) => {
                const newProductDTOs = prev.productDTOs;
                newProductDTOs[i] = {
                  ...newProductDTOs[i],
                  remark: e.target.value,
                };
                return { ...prev, productDTOs: newProductDTOs };
              });
            }}
            variant="standard"
          />
        }
      />
    </tr>
  );
};

export default AddSchemesTable;
