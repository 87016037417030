import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const PriceDropApi = {
  GetByFilters: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.drop.filter, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        month: payload.month,
        year: payload.year,
        userGroups: payload.userGroups,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  SearchByKeyword: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.drop.search, {
      params: {
        page: payload.page,
        size: payload.size,
        brandId: payload.brandId,
        month: payload.month,
        year: payload.year,
        keyword: payload.keyword,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  AddDrop: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.drop.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdateDrop: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.drop.baseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
