import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrandsApi } from "apis/BrandsApi";
import { NlcApi } from "apis/NlcApi";
import { GroupsApi } from "apis/GroupsApi";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import Popover from "@mui/material/Popover";
import { formatDateTimeComponent } from "utils";

const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

export default function NlcV2() {
  const navigate = useNavigate();
  const date = new Date();
  // const day = date.getDay();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;

  const color = "light";
  const [brands, setBrands] = useState([]);
  const [nlcList, setNlcList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [nlcGroups, setNlcGroups] = useState({});

  const handleGetGroupById = async (id) => {
    try {
      return await GroupsApi.GetGroupsListById(id);
    } catch (err) {}
  };

  useEffect(() => {
    axios.defaults.headers.common["x-api-key"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd";
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    getBrands();
  }, []);

  const getBrands = () => {
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        const b = res.data.data.filter((d) => d.isActive);
        setBrands(b);
        setSelectedBrand(b[0]);
        getNlc(b[0], 0);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getNlc = (brand, page) => {
    //console.log(selectedBrand)
    NlcApi.GetNlcV2ListByFilter({
      page: page,
      size: 100,
      periodFrom: periodFrom,
      periodTo: periodTo,
      brandId: brand.id,
    })
      .then((res) => {
        setTotalPages(res.data.pages);
        setNlcList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const searchNlc = (e) => {
    //console.log(selectedBrand)
    NlcApi.SearchNlc({
      page: 0,
      size: 20,
      periodFrom: periodFrom,
      periodTo: periodTo,
      brandId: selectedBrand.id,
      keyword: e.target.value,
    })
      .then((res) => {
        setNlcList(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleChange = (event) => {
    setSelectedBrand(event.target.value);
    getNlc(event.target.value, 0);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getNlc(selectedBrand, page - 1);
    return currentPage;
  };

  const [schemesAnchorEl, setSchemesAnchorEl] = React.useState(null);
  const [popoverSchemes, setPopoverSchemes] = React.useState([]);

  const handleClickSchemesPopover = (event, schemes) => {
    setSchemesAnchorEl(event.currentTarget);
    setPopoverSchemes(schemes);
  };

  const handleCloseSchemesPopover = () => {
    setSchemesAnchorEl(null);
    setPopoverSchemes([]);
  };
  const openSchemes = Boolean(schemesAnchorEl);
  const idSchemes = openSchemes ? "simpleSchemes-popover" : undefined;

  return (
    <>
      <div
        className={
          "relative flex flex-row align-center min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBrand}
                label="Brand"
                onChange={handleChange}>
                {brands.map((b, i) => (
                  <MenuItem key={i} value={b}>
                    {b.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="mb-3 pt-0">
            <input
              onChange={searchNlc}
              type="text"
              placeholder="Search Model by name"
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
            />
          </div>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="text-center p-2 mb-2">
            <div className="">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader value="" />
                <TableHeader heading="Name" />
                <TableHeader heading="NLC" />
                <TableHeader heading="DP" />
                <TableHeader heading="MOP" />
                <TableHeader heading="MRP" />
                <TableHeader heading="Schemes" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {nlcList.map((p, index, arr) => {
                return (
                  <tr
                    key={index}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={index + 1} />
                    <TableElement
                      value={
                        p.product.primaryName + " " + p.product.secondaryName
                      }
                    />
                    <TableElement value={Number(p?.price?.nlc)?.toFixed(2)} />
                    <TableElement value={Number(p?.price?.dp)?.toFixed(2)} />
                    <TableElement value={Number(p?.price?.mop)?.toFixed(2)} />
                    <TableElement value={Number(p?.price?.mrp)?.toFixed(2)} />

                    <TableElement
                      value={
                        <>
                          {p.schemes && p.schemes.length > 0 ? (
                            <Button
                              variant="text"
                              onClick={(event) =>
                                handleClickSchemesPopover(event, p.schemes)
                              }>
                              <span className="font-bold"> Show</span>
                            </Button>
                          ) : (
                            <></>
                          )}

                          <Popover
                            elevation={1}
                            id={idSchemes}
                            sx={{ boxShadow: "none" }}
                            open={openSchemes}
                            anchorEl={schemesAnchorEl}
                            onClose={handleCloseSchemesPopover}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}>
                            <Typography sx={{ p: 2 }}>
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-100">
                                  <tr className="divide-x divide-gray-200 ">
                                    <TableHeader heading="" />
                                    <TableHeader heading="Name" />
                                    <TableHeader heading="Amount" />
                                    <TableHeader heading="Remark" />
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {popoverSchemes.map((s, i) => (
                                    <tr
                                      key={i}
                                      className="divide-x divide-gray-200 hover:bg-slate-100">
                                      <TableElement value={i + 1} />
                                      <TableElement
                                        value={<>{s.name ? s.name : ""}</>}
                                        type="element"
                                      />
                                      <TableElement value={s.amount} />
                                      <TableElement
                                        style={{ whiteSpace: "pre" }}
                                        value={s.remark}
                                      />
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Typography>
                          </Popover>
                        </>
                      }
                    />
                    {/* <TableElement
                      value={p.groups
                        .map((g) => {
                          return nlcGroups[g];
                        })
                        .join(", ")}
                    /> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
