import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const AutoComplete = ({
  sx,
  label,
  labelKey = "name",
  fetchOptions,
  onChange,
  multiple = true,
  defaultValue,
  getOptionLabel
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [input, setInput] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setOptions(await fetchOptions(input));
    };

    setLoading(true);
    if (input && input.length > 1) {
      fetchData();
    }
    setLoading(false);
  }, [input]);
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={sx}
      fullWidth
      defaultValue={defaultValue}
      multiple={multiple}
      onInputChange={(event, value, reason) => {
        setInput(value);
      }}
      onChange={onChange}
      loading={loading}
      getOptionLabel={getOptionLabel}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    />
  );
};

export default AutoComplete;
