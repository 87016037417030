import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const NotificationTopicsApi = {
  GetNotification: async () => {
    const response = await axios.get(
      stage_url + sub_urls.notification.tokenBaseUrl,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetNotificationById: async (id) => {
    const response = await axios.get(
      stage_url + sub_urls.notification.tokenBaseUrl + "/" + id,
      {
        params: {
          id: id,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddNotification: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.notification.tokenBaseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditNotification: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.notification.tokenBaseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
