/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ExcelExport from "components/ExcelExport/ExcelExport";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import { formatDate } from "utils";
import { getAllSubscriptions } from "apis/MydukanSubs";
import { getAllSubscriptionsByFilters } from "apis/MydukanSubs";
import { createSubscriptionApi } from "apis/MydukanSubs";
import { deleteSubscriptions } from "apis/MydukanSubs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MydukanSubscriptions() {
  const color = "light";
  const [usersSubscriptions, setUsersSubscriptions] = useState([]);

  const date = new Date();

  let fileName = `UserSubscriptionData_${date.getDate()}${
    date.getMonth() + 1
  }${date.getFullYear()}_${date.getHours()}${date.getMinutes()}`;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [mobileNo, setMobileNo] = useState("");
  const [totalElements, setTotalElements] = useState("");
  const [subscription, setSubscription] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    isActive: null,
    startDate: null,
    endDate: null,
    mobile: null,
  });
  const [userSize, setUserSize] = useState(25);

  const handleGetSubscription = async () => {
    try {
      const { data } = await getAllSubscriptions();
      setSubscription(data.data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const handleGetUserSubscriptions = async (page, size) => {
    try {
      const { data } = await getAllSubscriptionsByFilters({
        ...filterPayload,
        page,
        size,
      });
      setTotalElements(data.totalElements);
      setTotalPages(data.pages);
      setUsersSubscriptions(data.data);
      setExcelData(data.data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    (async () => {
      await handleGetSubscription();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handleGetUserSubscriptions(currentPage - 1, userSize);
    })();
  }, [currentPage, filterPayload, userSize]);

  const clearSearch = () => {
    setFilterPayload({
      isActive: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      price: null,
      direction: null,
      mobile: null,
    });
    setCurrentPage(1);
  };

  const [openExcelDialog, setOpenExcelDialog] = React.useState(false);
  const handleClickOpenExcelDialog = () => {
    setOpenExcelDialog(true);
  };
  const handleCloseExcelDialog = () => {
    setOpenExcelDialog(false);
  };
  const [excelData, setExcelData] = useState([]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Grid container spacing={2}>
            <Grid xs={10}>
              <Grid container>
                <TextField
                  fullWidth
                  label="Search by Mobile number"
                  id="fullWidth"
                  sx={{ width: "85%" }}
                  value={mobileNo}
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                    if (e.target.value?.length === 10) {
                      setFilterPayload({
                        ...filterPayload,
                        mobile: e.target.value,
                      });
                    } else if (e.target.value?.length === 0) {
                      setFilterPayload({
                        ...filterPayload,
                        mobile: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                {/* <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Chip label="General Filters" />
            </Grid> */}
                <Grid sx={{ width: "25%" }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFilterPayload({
                        ...filterPayload,
                        startDate: e.target?.value,
                      });
                    }}
                    value={filterPayload?.startDate}
                    fullWidth
                    id="outlined-basic"
                    helperText="Start Date"
                    variant="outlined"
                  />
                </Grid>
                <Grid sx={{ width: "25%" }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFilterPayload({
                        ...filterPayload,
                        endDate: e.target?.value,
                      });
                    }}
                    value={filterPayload?.endDate}
                    fullWidth
                    id="outlined-basic"
                    helperText="End Date"
                    variant="outlined"
                  />
                </Grid>
                <Grid>
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Is Active
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group"
                      name="Is Active"
                      value={filterPayload?.isActive}
                      onChange={(e) => {
                        setFilterPayload({
                          ...filterPayload,
                          isActive: e.target?.value,
                        });
                      }}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"True"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={"False"}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}>
                  <Grid sx={{ width: "50%" }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      onClick={clearSearch}>
                      Reset All
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={2}>
              <div className="grid grid-cols-3 gap-4 content-center">
                <AddUserSubscription subscription={subscription} />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="text-center p-2 mb-2"></div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Validity" />
                <TableHeader heading="Price" />
                <TableHeader heading="Disc Price" />
                <TableHeader heading="Message" />
                <TableHeader heading="Active" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {subscription?.map((s, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />
                    <TableElement value={<>{s.subscriptionName}</>} />
                    <TableElement value={s.subscriptionTime} />
                    <TableElement value={s.subscriptionPrice} />
                    <TableElement value={s.subscriptionDiscount} />
                    <TableElement value={s.subscriptionDesc} />
                    <TableElement value={s.active} type="boolean" />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="w-full flex justify-end p-2 text-sky-500">
            Total: {totalElements}
          </div>
          <div className="text-center p-2 mb-2">
            <div className="">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
            <div className="text-left ml-3 mt-2">
              <Button
                variant="contained"
                color="success"
                onClick={handleClickOpenExcelDialog}>
                Export Data
              </Button>
              <Dialog
                open={openExcelDialog}
                onClose={handleCloseExcelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  Export Subscription Data
                </DialogTitle>
                <DialogContent>
                  You are Downloading subscription data of{" "}
                  <TextField
                    id="filled-basic"
                    onChange={(e) => {
                      setUserSize(e.target.value);
                    }}
                    variant="standard"
                    sx={{ width: "60px" }}
                    value={userSize}
                  />{" "}
                  users
                </DialogContent>
                <DialogActions>
                  <ExcelExport excelData={excelData} fileName={fileName} />
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="User" />
                <TableHeader heading="Active" />
                <TableHeader heading="Price" />
                <TableHeader heading="Sub NAME" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="Start Date" />
                <TableHeader heading="End Date" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {usersSubscriptions?.map((us, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement
                      value={userSize * (currentPage - 1) + i + 1}
                    />
                    <TableElement value={us.userName} />
                    <TableElement value={us.isActive} type="boolean" />
                    <TableElement value={us.subscriptionPrice} />
                    <TableElement value={us.subscriptionName} />
                    <TableElement value={us.userPhoneNumber} />
                    <TableElement value={formatDate(us.startDate)} />
                    <TableElement value={formatDate(us.endDate)} />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-red-500 hover:text-sky-950 font-bold gr "
                          onClick={async () => {
                            try {
                              await deleteSubscriptions(us.userSubscriptionId);
                              toast("Subscription Deleted.", {
                                type: "success",
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 1000,
                              });
                            } catch (error) {
                              toast(error?.response?.data?.message, {
                                type: "error",
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 1000,
                              });
                            }
                          }}>
                          DELETE
                        </span>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

const AddUserSubscription = ({ subscription }) => {
  const date = new Date();
  const currentDate =
    date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

  const [open, setOpen] = useState(false);

  const [payload, setPayload] = useState({
    mibile: null,
    subscriptionId: null,
    startDate: currentDate,
  });

  return (
    <>
      <div className="flex justify-center">
        <div className="">
          <button
            onClick={() => {
              setPayload({
                mobile: null,
                subscriptionId: null,
                startDate: currentDate,
              });
              setOpen(true);
            }}
            className="bg-lightBlue-800 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            <span className="mr-2">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            Add Subscription
          </button>
        </div>
      </div>

      {/* Add Subscription */}
      <div>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Subscription
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <>
              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr className="divide-x divide-gray-200 ">
                      <TableHeader heading="" />
                      <TableHeader heading="Mobile" />
                      <TableHeader heading="Start Date" />
                      <TableHeader heading="Select Subscription" />
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr
                      style={{ padding: "5px" }}
                      className="divide-x divide-gray-200 hover:bg-slate-100">
                      <TableElement value={1} />
                      <TableElement
                        value={
                          <TextField
                            inputProps={{ maxLength: 10 }}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                mobile: e.target?.value,
                              });
                            }}
                            value={payload?.mobile}
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                          />
                        }
                        type="element"
                      />
                      <TableElement
                        value={
                          <>
                            <TextField
                              type="date"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  startDate: e?.target.value,
                                })
                              }
                              value={payload.startDate}
                              fullWidth
                              id="outlined-basic"
                              label="Start Date"
                              variant="outlined"
                            />
                          </>
                        }
                      />
                      <TableElement
                        value={
                          <>
                            <div className="p-2">
                              <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Subscription"
                                value={payload.subscriptionId}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    subscriptionId: e?.target?.value,
                                  });
                                }}>
                                {subscription?.map((m, i) => (
                                  <MenuItem key={i} value={m?.subscriptionId}>
                                    {m?.subscriptionName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </>
                        }
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-right">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  sx={{ m: 4 }}
                  onClick={async () => {
                    try {
                      await createSubscriptionApi(payload);
                      setOpen(false);
                      toast("Subscription Added.", {
                        type: "success",
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                      });
                    } catch (error) {
                      toast(error?.response?.data?.message, {
                        type: "error",
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                      });
                    }
                  }}>
                  Add Subscription
                </Button>
              </div>
            </>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default MydukanSubscriptions;
