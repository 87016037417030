import { analytics_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const AnalyticsApi = {
  GetEventByFilter: async (payload) => {
    const response = await axios.get(
      analytics_url + sub_urls.event.getEventByFilter,
      {
        params: {
          from: payload.from,
          to: payload.to,
          name: payload.name,
          type: payload.type,
          category: payload.category,
          mobile: payload.mobile,
          email: payload.email,
          sortBy: payload.sortBy,
          direction: payload.direction,
          page: payload.page,
          size: payload.size,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },

  GetUniqueEventByFilter: async (payload) => {
    const response = await axios.get(
      analytics_url + sub_urls.event.getUniqueEventByFilter,
      {
        params: {
          category: payload.category,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetEventCountByFilter: async (payload) => {
    const response = await axios.get(
      analytics_url + sub_urls.event.getEventCountByFilter,
      {
        params: {
          category: payload.category,
          from: payload.from,
          to: payload.to,
          name: payload.name,
          sortBy: payload.sortBy,
          direction: payload.direction,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
