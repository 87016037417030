/*eslint-disable*/
import React from "react";
import { Link, NavLink } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [active, setActive] = React.useState("");

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}>
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/">
            SB Admin
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }>
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/">
                    SB Admin
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12  border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "brands" ||
                    window.location.href.indexOf("/admin/brands") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("brands")}
                  to="/admin/brands">
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (active === "brands" ||
                      window.location.href.indexOf("/admin/brands") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Brands
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "products" ||
                    window.location.href.indexOf("/admin/products") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("products")}
                  to="/admin/products">
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (active === "products" ||
                      window.location.href.indexOf("/admin/products") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Products
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "schemes" ||
                    window.location.href.indexOf("/admin/schemes") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("schemes")}
                  to="/admin/schemes">
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (active === "schemes" ||
                      window.location.href.indexOf("/admin/schemes") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Schemes
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "nlc" ||
                    window.location.href.indexOf("/admin/nlc") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("nlc")}
                  to="/admin/nlc">
                  <i
                    className={
                      "fas fa-barcode mr-2 text-sm " +
                      (active === "nlc" ||
                      window.location.href.indexOf("/admin/nlc") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  NLC
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "nlc-v2" ||
                    window.location.href.indexOf("/admin/nlc-v2") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("nlc-v2")}
                  to="/admin/nlc-v2">
                  <i
                    className={
                      "fas fa-barcode mr-2 text-sm " +
                      (active === "nlc-v2" ||
                      window.location.href.indexOf("/admin/nlc-v2") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  NLC V2
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "priceDrop" ||
                    window.location.href.indexOf("/admin/price-drop") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("priceDrop")}
                  to="/admin/price-drop">
                  <i
                    className={
                      "fas fa-chart-simple mr-2 text-sm " +
                      (active === "priceDrop" ||
                      window.location.href.indexOf("/admin/price-drop") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Price Drop
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "posts" ||
                    window.location.href.indexOf("/admin/posts") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("posts")}
                  to="/admin/posts">
                  <i
                    className={
                      "fas fa-ioxhost mr-2 text-sm " +
                      (active === "posts" ||
                      window.location.href.indexOf("/admin/posts") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Posts
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "notification-topics" ||
                    window.location.href.indexOf(
                      "/admin/notification-topics"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("share")}
                  to="/admin/share">
                  <i
                    className={
                      "fas fa-share mr-2 text-sm " +
                      (active === "share" ||
                      window.location.href.indexOf("/admin/share") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Share
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "files" ||
                    window.location.href.indexOf("/admin/files") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("files")}
                  to="/admin/files">
                  <i
                    className={
                      "fas fa-upload mr-2 text-sm " +
                      (active === "files" ||
                      window.location.href.indexOf("/admin/files") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Files
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "states" ||
                    window.location.href.indexOf("/admin/states") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("states")}
                  to="/admin/states">
                  <i
                    className={
                      "fas fa-landmark mr-2 text-sm " +
                      (active === "states" ||
                      window.location.href.indexOf("/admin/states") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  States
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "district" ||
                    window.location.href.indexOf("/admin/district") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("district")}
                  to="/admin/district">
                  <i
                    className={
                      "fas fa-monument mr-2 text-sm " +
                      (active === "district" ||
                      window.location.href.indexOf("/admin/district") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  District
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "groups" ||
                    window.location.href.indexOf("/admin/groups") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("groups")}
                  to="/admin/groups">
                  <i
                    className={
                      "fas fa-layer-group mr-2 text-sm " +
                      (active === "groups" ||
                      window.location.href.indexOf("/admin/groups") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Groups
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "subscriptions" ||
                    window.location.href.indexOf("/admin/subscriptions") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("subscriptions")}
                  to="/admin/subscriptions">
                  <i
                    className={
                      "fas fa-person-shelter mr-2 text-sm " +
                      (active === "subscriptions" ||
                      window.location.href.indexOf("/admin/subscriptions") !==
                        -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Subscriptions
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "bulkSubscription" ||
                    window.location.href.indexOf("/admin/bulkSubscription") !==
                      -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("bulkSubscription")}
                  to="/admin/bulkSubscription">
                  <i
                    className={
                      "fas fa-chess-bishop mr-2 text-sm " +
                      (active === "bulkSubscription" ||
                      window.location.href.indexOf(
                        "/admin/bulkSubscription"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Bulk Subscription
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "notifications" ||
                    window.location.href.indexOf("/admin/notifications") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("notifications")}
                  to="/admin/notifications">
                  <i
                    className={
                      "fas fa-person-shelter mr-2 text-sm " +
                      (active === "notifications" ||
                      window.location.href.indexOf("/admin/notifications") !==
                        -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Notifications
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "totp" ||
                    window.location.href.indexOf("/admin/totp") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("totp")}
                  to="/admin/totp">
                  <i
                    className={
                      "fas fa-lock mr-2 text-sm " +
                      (active === "totp" ||
                      window.location.href.indexOf("/admin/totp") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  TOTP
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "users" ||
                    window.location.href.indexOf("/admin/users") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("users")}
                  to="/admin/users">
                  <i
                    className={
                      "fas fa-users mr-2 text-sm " +
                      (active === "users" ||
                      window.location.href.indexOf("/admin/users") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Users
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "whatsapp" ||
                    window.location.href.indexOf("/admin/whatsapp") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("whatsapp")}
                  to="/admin/whatsapp">
                  <i
                    className={
                      "fas fa-ioxhost mr-2 text-sm " +
                      (active === "whatsapp" ||
                      window.location.href.indexOf("/admin/whatsapp") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Whatsapp
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "notification-topics" ||
                    window.location.href.indexOf(
                      "/admin/notification-topics"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("notification-topics")}
                  to="/admin/notification-topics">
                  <i
                    className={
                      "fas fa-bell mr-2 text-sm " +
                      (active === "notification-topics" ||
                      window.location.href.indexOf(
                        "/admin/notification-topics"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Notification Topic
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "mydukanSubscriptions" ||
                    window.location.href.indexOf("/admin/mydukanSubscriptions") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("mydukanSubscriptions")}
                  to="/admin/mydukanSubscriptions">
                  <i
                    className={
                      "fas fa-chart-simple mr-2 text-sm " +
                      (active === "mydukanSubscriptions" ||
                      window.location.href.indexOf("/admin/mydukanSubscriptions") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  MyDukaan Subscriptions
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (active === "analytics" ||
                    window.location.href.indexOf("/admin/analytics") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  onClick={() => setActive("analytics")}
                  to="/admin/analytics">
                  <i
                    className={
                      "fas fa-ioxhost mr-2 text-sm " +
                      (active === "analytics" ||
                      window.location.href.indexOf("/admin/analytics") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Analytics
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
