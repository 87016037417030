import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const DistrictApi = {
  GetDistrictsListByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.district.getDistrictsByFilter,
      {
        params: {
          stateId: payload.stateId,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddDistrict: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.district.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
