import React from "react";

function GroupsTable({ name, isActive, Type, createdOn, lastUpdatedOn, onEditClick, row }) {
  const edit = () => {
    onEditClick(row);
}
  const handleClick = () => {
      edit();
  };
  return (
    <>
      <tr>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {name}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {isActive ? (
            <i className="fas fa-circle text-emerald-500 mr-2"></i>
          ) : (
            <i className="fas fa-circle text-red-500 mr-2"></i>
          )}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {Type}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {createdOn}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {lastUpdatedOn}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                <button onClick={handleClick} className="bg-lightGray-500 text-black active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    Edit
                </button>
            </td>
      </tr>
    </>
  );
}

export default GroupsTable;
