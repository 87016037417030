import React from "react";

const TableHeader = ({ heading }) => {
  return (
    <th
      scope="col"
      className="px-4 py-3.5  text-sm font-normal text-gray-500 text-center">
      <span className="font-bold uppercase text-sm"> {heading}</span>
    </th>
  );
};

export default TableHeader;
