import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { GroupsApi } from "apis/GroupsApi";
import { StatesApi } from "apis/StatesApi";
import { DistrictApi } from "apis/DistrictApi";
import { createSvgIcon } from "@mui/material/utils";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import GroupsTable from "components/Tables/GroupsTable";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function Groups() {
  const navigate = useNavigate();
  const color = "light";
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const [isEdit, setIsEdit] = useState(false);
  const [pincodes, setPincodes] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    type: "ALL",
    states: [],
    districts: [],
    pincodes: [],
    isActive: true,
    isDeleted: true,
    createdOn: "",
    lastUpdatedOn: "",
  });
  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const searchGroups = (e) => {
    setSearchTerm(e.target.value);
  };
  const [chipData, setChipData] = React.useState(formData.states);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [pincodeInput, setPincodeInput] = useState("");

  const handleDelete = (chipToDelete) => () => {
    const updatedStates = formData.states.filter(
      (state) => state !== chipToDelete
    );

    setFormData({
      ...formData,
      states: updatedStates,
    });

    setChipData(updatedStates);
  };
  const handleDeleteDistrict = (districtToDelete) => () => {
    // Remove the districtToDelete value from selectedDistricts
    const updatedSelectedDistricts = selectedDistricts.filter(
      (district) => district !== districtToDelete
    );

    // Update selectedDistricts state
    setSelectedDistricts(updatedSelectedDistricts);

    // Update formData with the updated selectedDistricts array
    setFormData({
      ...formData,
      districts: updatedSelectedDistricts,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddPincode = (e) => {
    e.preventDefault();
    if (pincodeInput.trim() !== "") {
      setPincodes([...pincodes, pincodeInput]);

      setFormData({
        ...formData,
        pincodes: [...formData.pincodes, pincodeInput],
      });

      setPincodeInput("");
    }
  };
  const handleDeletePincode = (pincodeToDelete) => {
    const updatedPincodes = pincodes.filter(
      (pincode) => pincode !== pincodeToDelete
    );
    setPincodes(updatedPincodes);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSave = () => {
    GroupsApi.AddGroup(formData)
      .then((res) => {
        toast("Group Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        setSelectedDistricts([]);
        setSelectedStates([]);
        setPincodeInput("");
        setPincodes([]);
        setChipData([]);
        setFormData({
          name: "",
          description: "",
        });
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getStates = () => {
    StatesApi.GetStatesListByFilter()
      .then((res) => {
        setStates(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getDistricts = () => {
    DistrictApi.GetDistrictsListByFilter()
      .then((res) => {
        setDistrict(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    let filteredDistricts = [];

    district.forEach((d) => {
      if (chipData.includes(d.state.name)) {
        filteredDistricts.push(d);
      }
    });

    if (filteredDistricts.length > 0) {
      setDistrict(filteredDistricts);
    } else {
      setDistrict([]);
    }
  }, [selectedStates, chipData]);
  const getGroups = () => {
    GroupsApi.GetGroupsListByFilter({
      type: type,
      page: 0,
      size: 99,
    })
      .then((res) => {
        setGroups(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getGroups();
  }, [type]);

  const editGroup = (row) => {
    setIsEdit(true);
    //console.log(row);
    setFormData({
      id: row.id,
      name: row.name,
      description: row.description,
      type: row.type,
      states: row.states,
      districts: row.districts,
      pincodes: row.pincodes,
      isActive: row.isActive,
      isDeleted: row.isDeleted,
      createdOn: row.createdOn,
      lastUpdatedOn: row.lastUpdatedOn,
    });
    setOpen(true);
  };

  const edit = () => {
    GroupsApi.EditGroup(formData.id, formData)
      .then((res) => {
        toast("Group Updated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        if (err.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    axios.defaults.headers.common["x-api-key"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd";
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    getStates();
    getGroups();
    getDistricts();
    setChipData(formData.states);
  }, []);
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Grid container spacing={2}>
            <Grid xs={10}>
              <TextField
                fullWidth
                label="Search by Group Name"
                id="fullWidth"
                onChange={searchGroups}
              />
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={handleClickOpen}>
                + Add Group
              </Button>
            </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Enter Details</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid xs={6} md={6}>
                  <TextField
                    id="outlined-basic"
                    sx={{ m: 2 }}
                    label="Name"
                    name="name"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleChange}
                  />

                  <TextField
                    id="outlined-multiline-static"
                    sx={{
                      width: { sm: 200, md: 225 },
                      m: 2,
                    }}
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                  {formData.type === "PIN" || formData.type === "ALL" ? (
                    <form>
                      <div style={{ margin: "15px" }}>
                        <TextField
                          id="outlined-basic"
                          // sx={{ m: 2 }}
                          label="Pincode"
                          name="pincodes"
                          variant="outlined"
                          value={pincodeInput}
                          onChange={(e) => setPincodeInput(e.target.value)}
                        />
                        <Button
                          type="submit"
                          // sx={{ m: 2 }}
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={handleAddPincode}>
                          Add Pincode
                        </Button>
                        <Paper
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            listStyle: "none",
                            p: 0.5,
                            // m: 2,
                          }}
                          component="ul">
                          {pincodes.map((pincode, index) => (
                            <ListItem key={index}>
                              <Chip
                                label={pincode}
                                onDelete={() => handleDeletePincode(pincode)}
                              />
                            </ListItem>
                          ))}
                        </Paper>
                      </div>
                    </form>
                  ) : (
                    <></>
                  )}
                  <FormControl sx={{ m: 2 }}>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      IsActive
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group"
                      name="isActive"
                      value={formData.isActive}
                      onChange={handleChange}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid xs={6} md={6}>
                  <FormControl sx={{ m: 2 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.type}
                      label="Type"
                      name="type"
                      onChange={handleChange}>
                      <MenuItem value={"ALL"}>ALL</MenuItem>
                      <MenuItem value={"STATE"}>STATE</MenuItem>
                      <MenuItem value={"DISTRICT"}>DISTRICT</MenuItem>
                      <MenuItem value={"PIN"}>PIN</MenuItem>
                    </Select>
                  </FormControl>
                  {formData.type === "STATE" || formData.type === "ALL" ? (
                    <FormControl sx={{ m: 2 }} fullWidth>
                      <MatAutoComplete
                        label={"Select State"}
                        getData={getStates}
                        options={states}
                        setOptions={setStates}
                        setValue={(value) => {
                          if (value && value.id) {
                            setFormData({
                              ...formData,
                              states: [...formData.states, value.id],
                            });
                            setSelectedStates(value.name);
                            setChipData((chips) => [...chips, value.name]);
                          }
                        }}
                        multiple={true}
                      />
                      <Paper
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul">
                        {chipData.map((data) => {
                          let icon;

                          return (
                            <ListItem key={data}>
                              <Chip
                                icon={icon}
                                label={data}
                                onDelete={handleDelete(data)}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                  {formData.type === "DISTRICT" || formData.type === "ALL" ? (
                    <FormControl sx={{ m: 2 }} fullWidth>
                      <MatAutoComplete
                        key={district.id}
                        label={"Select District"}
                        getData={getDistricts}
                        options={district}
                        optionLabel={district}
                        setOptions={setDistrict}
                        setValue={(value) => {
                          if (value && value.id) {
                            setFormData({
                              ...formData,
                              districts: [...formData.districts, value.id],
                            });
                            setSelectedDistricts((prevSelected) => [
                              ...prevSelected,
                              value.name,
                            ]);
                          }
                        }}
                        multiple={true}
                      />
                      <Paper
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul">
                        {selectedDistricts.map((data) => (
                          <ListItem key={data}>
                            <Chip
                              label={data}
                              onDelete={handleDeleteDistrict(data)}
                            />
                          </ListItem>
                        ))}
                      </Paper>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                  <FormControl sx={{ m: 2 }}>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      IsDelete
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group"
                      name="isDeleted"
                      value={formData.isDeleted}
                      onChange={handleChange}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isEdit ? (
                <Button onClick={edit}>Save Changes</Button>
              ) : (
                <Button onClick={handleSave}>Save</Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <FormControl sx={{ m: 2 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Type"
            name="type"
            onChange={(e) => setType(e.target.value)}>
            <MenuItem value={"ALL"}>ALL</MenuItem>
            <MenuItem value={"STATE"}>STATE</MenuItem>
            <MenuItem value={"DISTRICT"}>DISTRICT</MenuItem>
            <MenuItem value={"PIN"}>PIN</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Is Active" />
                <TableHeader heading="Type" />
                <TableHeader heading="Created on" />
                <TableHeader heading="Updated on" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {groups?.map((g, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />
                    <TableElement value={g.name} />
                    <TableElement value={g.isActive} type="boolean" />
                    <TableElement value={g.type} />
                    <TableElement value={g.createdOn} />
                    <TableElement value={g.lastUpdatedOn} />
                    <TableElement
                      value={
                        <span
                          className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                          onClick={() => editGroup(g)}>
                          EDIT
                        </span>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Groups;
