import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const PostsApi = {
  GetPostsListByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.post.getPostsByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          brandId: payload.brandId,
          productId: payload.productId,
          // userGroups: payload.userGroups,
          category: payload.category,
          postType: payload.postType,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  GetSharePostsListByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.post_share.getPostsByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          brandId: payload.brandId,
          type: payload.type,
          name: payload.name,
          mobile: payload.mobile,
          // userGroups: payload.userGroups,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddSharePost: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.post_share.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdatePosts: async (payload) => {
    const response = await axios.put(
      stage_url + sub_urls.post.baseUrl + "/" + payload.id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  UpdateSharePosts: async (payload) => {
    const response = await axios.put(
      stage_url + sub_urls.post_share.baseUrl + "/" + payload.id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddPost: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.post.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
