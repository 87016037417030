import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const NotificationApi = {
  GetNotification: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.notification.getNotifcationByFilter,
      {
        params: { page: payload.page },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },

  SendNotification: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.notification.sendNotification,
      { ...payload, data: {} },
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditNotification: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.notification.baseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
