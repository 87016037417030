import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import { DistrictApi } from "apis/DistrictApi";
import { StatesApi } from "apis/StatesApi";
import DistrictTable from "components/Tables/DistrictTable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

function District() {
  const navigate = useNavigate();
  const color = "light";
  const [district, setDistrict] = useState([]);
  const [states, setStates] = useState([]);
  const [open, setOpen] = useState(false);
  const [districtData, setDistrictData] = useState({
    id: "",
    name: "",
    isActive: true,
    state: {
      id: "",
      name: "",
      code: "",
      isActive: true,
    },
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "id") {
      setDistrictData({
        ...districtData,
        state: {
          ...districtData.state,
          id: value,
        },
      });
    } else {
      setDistrictData({
        ...districtData,
        [name]: value,
      });
    }
    // console.log(districtData);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    DistrictApi.AddDistrict(districtData)
      .then((res) => {
        toast("District Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getDistricts = () => {
    DistrictApi.GetDistrictsListByFilter({
      stateId: null,
    })
      .then((res) => {
        setDistrict(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const getStates = () => {
    StatesApi.GetStatesListByFilter()
      .then((res) => {
        setStates(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const [selectedState, setSelectedState] = useState("");

  const handleChangeState = (event) => {
    const stateId = event.target.value;
    setSelectedState(stateId);
    handleSelectState(stateId);
  };
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  const handleSelectState = (stateId) => {
    const filtered = district.filter((d) => d.state.id === stateId);
    setFilteredDistricts(filtered);
  };
  const handleResetFilter = () => {
    setSelectedState("");
    setFilteredDistricts(district);
  };

  useEffect(() => {
    axios.defaults.headers.common["x-api-key"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd";
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    getDistricts();
    getStates();
  }, []);

  useEffect(() => {
    setFilteredDistricts(district);
  }, [district]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <span className="text-right">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleClickOpen}>
            + Add District
          </Button>
        </span>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add District</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid xs={6} md={6}>
                <TextField
                  id="outlined-basic"
                  sx={{ m: 2 }}
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={districtData.name}
                  onChange={handleChange}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={districtData.state.id}
                    label="State"
                    onChange={handleChange}
                    sx={{ m: 2 }}
                    name="id">
                    {states.map((i, index) => {
                      if (i.name) {
                        return (
                          <MenuItem key={index} value={i.id}>
                            {i.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} md={6}>
                <FormControl sx={{ m: 2 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    IsActive
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="isActive"
                    value={districtData.isActive}
                    onChange={handleChange}>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Filter District by State
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={selectedState}
            onChange={handleChangeState}
            sx={{ m: 2 }}>
            {states.map((i, index) => {
              if (i.name) {
                return (
                  <MenuItem key={index} value={i.id}>
                    {i.name}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
        <div>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}>
            <Grid sx={{ width: "13%" }}>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={handleResetFilter}>
                Reset All
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="State" />
                <TableHeader heading="Is Active" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filteredDistricts?.map((d, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />
                    <TableElement value={d.name} />
                    <TableElement value={d?.state?.name} />
                    <TableElement value={d.isActive} type="boolean" />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default District;
