import { sub_urls, onboarding_url } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const UsersApi = {
  GetUsersListByFilter: async (payload) => {
    const response = await axios.get(
      onboarding_url + sub_urls.user.getUsersByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
          sortBy: payload.sortBy,
          direction: payload.direction,
          mobile: payload.mobile,
          roles: payload.roles,
          isApproved: payload.isApproved,
          isDeleted: payload.isDeleted,
          isLocked: payload.isLocked,
          isJoinedWhatsapp: payload.isJoinedWhatsapp,
          isGSTVerified: payload.isGSTVerified,
          isVerified: payload.isVerified,
          "business.address.state.id": payload.state,
          "business.address.district.id": payload.district,
          "business.address.pincode": payload.pincode,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditUsers: async (id, payload) => {
    const response = await axios.put(
      onboarding_url + sub_urls.user.baseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditBusinessProfile: async (id, payload) => {
    const response = await axios.put(
      onboarding_url + sub_urls.user.updateBusinessProfile + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditUsersAddress: async (id, payload) => {
    const response = await axios.put(
      onboarding_url + sub_urls.user.updateBussinessAddress + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
