import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { PostsApi } from "apis/PostsApi";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import { useNavigate } from "react-router";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProductsApi } from "apis/ProductsApi";
import { BrandsApi } from "apis/BrandsApi";
import { GroupsApi } from "apis/GroupsApi";
import Grid from "@mui/material/Unstable_Grid2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DialogContent, DialogTitle, Tooltip } from "@mui/material";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { formatDate } from "utils";
import { formatDateTimeComponent } from "utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

function Posts() {
  const navigate = useNavigate();
  const color = "light";
  const [posts, setPosts] = useState();
  const [open, setOpen] = React.useState(false);
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [brands, setBrands] = useState([]);
  const [selectedBrandInAddBrand, setSelectedBrandInAddBrand] = useState({});

  const [products, setProducts] = useState([]);
  const [postAddPayload, setPostAddPayload] = useState({
    id: "",
    brand: null,
    product: null,
    url: "",
    periodFrom: periodFrom,
    periodTo: periodTo,
    postType: "",
    type: "",
    category: "",
    groups: [
      {
        id: "",
        name: "",
      },
    ],
    displayOrder: "",
    isActive: true,
    isShareable: false,
    isDeleted: false,
  });

  const [brandFilter, setBrandFilter] = useState({});
  const [productFilter, setProductFilter] = useState({});
  // const [userGroups, setUserGroups] = useState(null);
  const [category, setCategory] = useState(null);
  const [postType, setPostType] = useState(null);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getPosts(page - 1);
    return currentPage;
  };
  const [groupOptions, setGroupOptions] = useState([]);
  const getGroupData = (searchTerm) => {
    GroupsApi.GetGroupsListBySearch({
      page: 0,
      size: 10,
      keyword: searchTerm,
    }).then((res) => {
      const filter = res.data.data.filter((ele) => ele.isActive);
      const updatedOptions = filter.map((p) => {
        return {
          name: p.name,
          id: p.id,
        };
      });
      setGroupOptions(updatedOptions);
    });
  };
  const getSelectedGroups = (data) => {
    setPostAddPayload({
      ...postAddPayload,
      groups: data,
    });
  };

  const [totalElements, setTotalElements] = useState("");

  const getPosts = (page) => {
    PostsApi.GetPostsListByFilter({
      page: page,
      size: 50,
      periodFrom: periodFrom,
      periodTo: periodTo,
      brandId: brandFilter?.id,
      productId: productFilter?.id,
      category: category,
      postType: postType,
    })
      .then((res) => {
        setPosts(res.data.data);
        setTotalPages(res.data.pages);
        setTotalElements(res.data.totalElements);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const handleResetFilter = () => {
    setBrandFilter("");
    setProductFilter("");
    setCategory("");
    // setUserGroups("")
    // getSelectedGroupsFilter("");
    setPostType("");
    getPosts(0);
    setCurrentPage(1);
  };
  const onBrandSelectInAddBrand = (event) => {
    const brand = event.target.value;
    if (event.target.value === null) {
      setPostAddPayload({
        ...postAddPayload,
        brand: null,
      });
      setSelectedBrandInAddBrand(null);
      setProducts([]);
    } else {
      setSelectedBrandInAddBrand(brand);
      setPostAddPayload({
        ...postAddPayload,
        brand: {
          id: brand.id,
          name: brand.name,
          image: brand.image,
        },
      });
      getProducts(brand.id);
    }
  };

  const [productsOptions, setProductsOptions] = useState([]);
  const getProductsData = (searchTerm) => {
    ProductsApi.SearchProducts({
      page: 0,
      size: 10,
      keyword: searchTerm,
      brandId: selectedBrandInAddBrand.id,
    }).then((res) => {
      const filter = res.data.data.filter((ele) => ele.isActive);
      const updatedOptions = filter.map((p) => {
        return {
          name: p.primaryName + " " + p.secondaryName,
          id: p.id,
        };
      });
      setProductsOptions(updatedOptions);
    });
  };
  const getSelectedProduct = (data) => {
    setPostAddPayload({
      ...postAddPayload,
      product: data,
    });
  };
  const save = () => {
    PostsApi.AddPost(postAddPayload)
      .then((res) => {
        toast("Post Added", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        getPosts(0);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openAddUpdateBrands = () => {
    setOpen(true);
  };
  const getBrands = () => {
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        setBrands(res.data.data.filter((d) => d.isActive));
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const getProducts = (id) => {
    ProductsApi.GetProductsListByFilter({
      page: 0,
      size: 99,
      brandId: id,
    })
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getPosts(currentPage - 1);
  }, [currentPage, brandFilter, productFilter, category, postType]);

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Grid container spacing={2}>
            <Grid xs={10}>
              <FormControl sx={{ width: "25%" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Brand
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={brandFilter.name}
                  label="Brand"
                  onChange={(e) => {
                    setBrandFilter(e.target.value);
                    getProducts(e.target.value.id);
                    setProductFilter("");
                    // console.log(filterPayload.brand.id)
                  }}>
                  <MenuItem key={0} value="">
                    --Select Brand--
                  </MenuItem>
                  {brands.map((b, i) => (
                    <MenuItem key={i + 1} value={b}>
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "25%" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Product
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productFilter.primaryName}
                  label="Product"
                  onChange={(e) => {
                    setProductFilter(e.target.value);
                  }}>
                  <MenuItem key={0} value="">
                    --Select Product--
                  </MenuItem>
                  {products.map((b, i) => (
                    <MenuItem key={i + 1} value={b}>
                      {b.primaryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "25%" }}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="category"
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}>
                  <MenuItem sx={{ color: "GrayText" }} value={null}>
                    --Select Category--
                  </MenuItem>
                  <MenuItem value={"PRICE_DROP"}>PRICE_DROP</MenuItem>
                  <MenuItem value={"COMING_SOON"}>COMING_SOON</MenuItem>
                  <MenuItem value={"SCHEMES_EXPIRING"}>
                    SCHEMES_EXPIRING
                  </MenuItem>
                  <MenuItem value={"SCHEMES_LAUNCHED"}>
                    SCHEMES_LAUNCHED
                  </MenuItem>
                  <MenuItem value={"PRODUCT_LAUNCHED"}>
                    PRODUCT_LAUNCHED{" "}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "25%" }}>
                <InputLabel id="demo-simple-select-label">Post Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={postType}
                  label="Post type"
                  onChange={(e) => {
                    setPostType(e.target.value);
                  }}>
                  <MenuItem sx={{ color: "GrayText" }} value={null}>
                    --Select Post Type--
                  </MenuItem>
                  <MenuItem value={"WHATSAPP"}>WHATSAPP</MenuItem>
                  <MenuItem value={"OFFERS"}>OFFERS</MenuItem>
                  <MenuItem value={"SAMACHAR"}>SAMACHAR</MenuItem>
                  <MenuItem value={"PROMOTIONS"}>PROMOTIONS</MenuItem>
                  <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
                  <MenuItem value={"PRICE_DROP"}>PRICE_DROP</MenuItem>
                  <MenuItem value={"PRICE_LIST"}>PRICE_LIST</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={2}>
              <button
                onClick={openAddUpdateBrands}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">
                <span className="mr-2">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                Add Post
              </button>
            </Grid>
          </Grid>
          <div>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}>
              <Grid sx={{ width: "13%" }}>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={handleResetFilter}>
                  Reset All
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-1">
          <div className="w-full flex justify-end p-2 text-sky-500">
            Total: {totalElements}
          </div>
          <div className="text-center p-2 mb-2">
            <div className="">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Brand Name" />
                <TableHeader heading="Title" />
                <TableHeader heading="Media " />
                <TableHeader heading=" From - To" />
                <TableHeader heading="Post Type" />
                <TableHeader heading="Category" />
                <TableHeader heading="Status" />
                <TableHeader heading="Desc" />
                <TableHeader heading="Groups" />
                <TableHeader heading="Created On" />
                <TableHeader heading="Type" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {posts &&
                posts.map((p, i) => (
                  <PostRow
                    currentPage={currentPage}
                    getPosts={getPosts}
                    p={p}
                    i={i}
                    periodFrom={periodFrom}
                    periodTo={periodTo}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ADD POST  */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Post
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <div className="">
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Brand
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedBrandInAddBrand}
                      label="Brand"
                      onChange={onBrandSelectInAddBrand}>
                      <MenuItem key={0} value={null}>
                        --Select Brand--
                      </MenuItem>
                      {brands.map((b, i) => (
                        <MenuItem key={i + 1} value={b}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <MatAutoComplete
                  label={"Search by Product name"}
                  getData={getProductsData}
                  options={productsOptions}
                  setOptions={setProductsOptions}
                  setValue={getSelectedProduct}
                  isMultiple={false}
                />
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <MatAutoComplete
                  label={"Search by Group name"}
                  getData={getGroupData}
                  options={groupOptions}
                  setOptions={setGroupOptions}
                  setValue={getSelectedGroups}
                  isMultiple={true}
                />
              </div>
            </ListItem>
          </div>

          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setPostAddPayload({
                    ...postAddPayload,
                    title: e.target.value,
                  })
                }
                value={postAddPayload.title}
                fullWidth
                id="outlined-basic"
                label="Title"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setPostAddPayload({
                    ...postAddPayload,
                    description: e.target.value,
                  })
                }
                value={postAddPayload.description}
                fullWidth
                id="outlined-basic"
                label="Description"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setPostAddPayload({
                    ...postAddPayload,
                    url: e.target.value,
                  })
                }
                value={postAddPayload.url}
                fullWidth
                id="outlined-basic"
                label="URL"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Post Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={postAddPayload.postType}
                    label="Post type"
                    onChange={(e) =>
                      setPostAddPayload({
                        ...postAddPayload,
                        postType: e.target.value,
                      })
                    }>
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Post Type--
                    </MenuItem>
                    <MenuItem value={"WHATSAPP"}>WHATSAPP</MenuItem>
                    <MenuItem value={"OFFERS"}>OFFERS</MenuItem>
                    <MenuItem value={"SAMACHAR"}>SAMACHAR</MenuItem>
                    <MenuItem value={"PROMOTIONS"}>PROMOTIONS</MenuItem>
                    <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
                    <MenuItem value={"PRICE_DROP"}>PRICE_DROP</MenuItem>
                    <MenuItem value={"PRICE_LIST"}>PRICE_LIST</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={postAddPayload.type}
                    label="type"
                    onChange={(e) =>
                      setPostAddPayload({
                        ...postAddPayload,
                        type: e.target.value,
                      })
                    }>
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Type--
                    </MenuItem>
                    <MenuItem value={"IMAGE"}>IMAGE</MenuItem>
                    <MenuItem value={"VIDEO"}>VIDEO</MenuItem>
                    <MenuItem value={"PDF"}>PDF</MenuItem>
                    <MenuItem value={"YOUTUBE"}>YOUTUBE</MenuItem>
                    <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={postAddPayload.category}
                    label="category"
                    onChange={(e) =>
                      setPostAddPayload({
                        ...postAddPayload,
                        category: e.target.value,
                      })
                    }>
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Category--
                    </MenuItem>
                    <MenuItem value={"PRICE_DROP"}>PRICE_DROP</MenuItem>
                    <MenuItem value={"COMING_SOON"}>COMING_SOON</MenuItem>
                    <MenuItem value={"SCHEMES_EXPIRING"}>
                      SCHEMES_EXPIRING
                    </MenuItem>
                    <MenuItem value={"SCHEMES_LAUNCHED"}>
                      SCHEMES_LAUNCHED
                    </MenuItem>
                    <MenuItem value={"PRODUCT_LAUNCHED"}>
                      PRODUCT_LAUNCHED{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setPostAddPayload({
                    ...postAddPayload,
                    displayOrder: e.target.value,
                  })
                }
                value={postAddPayload.displayOrder}
                fullWidth
                id="outlined-basic"
                label="Display Order"
                variant="outlined"
                type="number"
                helperText="Numerical value only"
              />
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  IsActive
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isActive"
                  value={postAddPayload.isActive}
                  onChange={(e) =>
                    setPostAddPayload({
                      ...postAddPayload,
                      isActive: e.target.value,
                    })
                  }>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Is Shareabale
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isShareable"
                  value={postAddPayload.isShareable}
                  onChange={(e) =>
                    setPostAddPayload({
                      ...postAddPayload,
                      isShareable: e.target.value,
                    })
                  }>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Is Deleted
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isDeleted"
                  value={postAddPayload.isDeleted}
                  onChange={(e) =>
                    setPostAddPayload({
                      ...postAddPayload,
                      isDeleted: e.target.value,
                    })
                  }>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <TextField
                type="date"
                onChange={(e) =>
                  setPostAddPayload({
                    ...postAddPayload,
                    periodFrom: e.target.value,
                  })
                }
                value={postAddPayload.periodFrom}
                fullWidth
                id="outlined-basic"
                label="Period From"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                type="date"
                onChange={(e) =>
                  setPostAddPayload({
                    ...postAddPayload,
                    periodTo: e.target.value,
                  })
                }
                value={postAddPayload.periodTo}
                fullWidth
                id="outlined-basic"
                label="Period To"
                variant="outlined"
              />
            </div>
          </ListItem>
        </List>
        <DialogActions>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </>
  );
}

export default Posts;

const PostRow = ({ p, i, periodFrom, periodTo, currentPage, getPosts }) => {
  const edit = (post) => {
    const payload = {
      id: post?.id,
      brand: {
        id: post?.brand?.id,
        name: post?.brand?.name,
        image: post?.brand?.image,
      },
      product: {
        id: post?.product?.id,
        primaryName: post?.product?.primaryName,
        secondaryName: post?.product?.secondaryName,
      },
      url: post?.url,
      periodFrom: post?.periodFrom,
      periodTo: post?.periodTo,
      postType: post?.postType,
      type: post?.type,
      category: post?.category,
      groups: post?.groups?.map((group) => ({
        id: group.id,
        name: group.name,
      })),
      displayOrder: post?.displayOrder,
      isActive: post?.isActive,
      isShareable: post?.isShareable,
      isDeleted: post?.isDeleted,
      title: post?.title,
      description: post?.description,
    };

    PostsApi.UpdatePosts(payload).then((res) => {
      toast("Post Updated", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      getPosts(currentPage - 1);
      setIsEditOpen(false);
    });
  };

  const [isDescOpen, setIsDescOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [editPostsPayload, setEditPostsPayload] = useState({
    url: "",
    title: "",
    description: "",
    periodFrom: periodFrom,
    periodTo: periodTo,
    postType: "",
    type: "",
    category: "",
    displayOrder: null,
    isActive: true,
    isShareable: true,
    isDeleted: true,
    groups: [],
  });

  const onEditClick = (row) => {
    setEditPostsPayload(row);
    setIsEditOpen(true);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsEditOpen(false)}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Posts - {p?.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <div className="">
            <ListItem className="mt-4">
              {/* <div className="block w-full overflow-x-auto">
                <MatAutoComplete
                  label={"Search by Group name"}
                  // getData={getGroupData}
                  // options={groupOptions}
                  // setOptions={setGroupOptions}
                  // setValue={getSelectedGroups}
                  isMultiple={true}
                />
              </div> */}
            </ListItem>
          </div>

          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setEditPostsPayload({
                    ...editPostsPayload,
                    title: e.target.value,
                  })
                }
                value={editPostsPayload.title}
                fullWidth
                id="outlined-basic"
                label="Title"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setEditPostsPayload({
                    ...editPostsPayload,
                    description: e.target.value,
                  })
                }
                value={editPostsPayload.description}
                fullWidth
                id="outlined-basic"
                label="Description"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setEditPostsPayload({
                    ...editPostsPayload,
                    url: e.target.value,
                  })
                }
                value={editPostsPayload.url}
                fullWidth
                id="outlined-basic"
                label="URL"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Post Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editPostsPayload.postType}
                    label="Post type"
                    onChange={(e) =>
                      setEditPostsPayload({
                        ...editPostsPayload,
                        postType: e.target.value,
                      })
                    }>
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Post Type--
                    </MenuItem>
                    <MenuItem value={"WHATSAPP"}>WHATSAPP</MenuItem>
                    <MenuItem value={"OFFERS"}>OFFERS</MenuItem>
                    <MenuItem value={"SAMACHAR"}>SAMACHAR</MenuItem>
                    <MenuItem value={"PROMOTIONS"}>PROMOTIONS</MenuItem>
                    <MenuItem value={"PRICE_DROP"}>PRICE_DROP</MenuItem>
                    <MenuItem value={"PRICE_LIST"}>PRICE_LIST</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editPostsPayload.type}
                    label="type"
                    onChange={(e) =>
                      setEditPostsPayload({
                        ...editPostsPayload,
                        type: e.target.value,
                      })
                    }>
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Type--
                    </MenuItem>
                    <MenuItem value={"IMAGE"}>IMAGE</MenuItem>
                    <MenuItem value={"VIDEO"}>VIDEO</MenuItem>
                    <MenuItem value={"PDF"}>PDF</MenuItem>
                    <MenuItem value={"YOUTUBE"}>YOUTUBE</MenuItem>
                    <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editPostsPayload.category}
                    label="category"
                    onChange={(e) =>
                      setEditPostsPayload({
                        ...editPostsPayload,
                        category: e.target.value,
                      })
                    }>
                    <MenuItem sx={{ color: "GrayText" }} value={null}>
                      --Select Category--
                    </MenuItem>
                    <MenuItem value={"PRICE_DROP"}>PRICE_DROP</MenuItem>
                    <MenuItem value={"COMING_SOON"}>COMING_SOON</MenuItem>
                    <MenuItem value={"SCHEMES_EXPIRING"}>
                      SCHEMES_EXPIRING
                    </MenuItem>
                    <MenuItem value={"SCHEMES_LAUNCHED"}>
                      SCHEMES_LAUNCHED
                    </MenuItem>
                    <MenuItem value={"PRODUCT_LAUNCHED"}>
                      PRODUCT_LAUNCHED{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                onChange={(e) =>
                  setEditPostsPayload({
                    ...editPostsPayload,
                    displayOrder: e.target.value,
                  })
                }
                value={editPostsPayload.displayOrder}
                fullWidth
                id="outlined-basic"
                label="Display Order"
                variant="outlined"
                type="number"
                helperText="Numerical value only"
              />
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  IsActive
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isActive"
                  value={editPostsPayload.isActive}
                  onChange={(e) =>
                    setEditPostsPayload({
                      ...editPostsPayload,
                      isActive: e.target.value,
                    })
                  }>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Is Shareable
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isShareable"
                  value={editPostsPayload.isShareable}
                  onChange={(e) =>
                    setEditPostsPayload({
                      ...editPostsPayload,
                      isShareable: e.target.value,
                    })
                  }>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <FormControl sx={{ m: 2 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Is Deleted
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="is Deleted"
                  value={editPostsPayload.isDeleted}
                  onChange={(e) =>
                    setEditPostsPayload({
                      ...editPostsPayload,
                      isDeleted: e.target.value,
                    })
                  }>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
          <ListItem className="mt-1">
            <div className="block w-full overflow-x-auto">
              <TextField
                type="date"
                onChange={(e) =>
                  setEditPostsPayload({
                    ...editPostsPayload,
                    periodFrom: e.target.value,
                  })
                }
                value={editPostsPayload.periodFrom}
                fullWidth
                id="outlined-basic"
                label="Period From"
                variant="outlined"
              />
            </div>
          </ListItem>
          <ListItem className="mt-4">
            <div className="block w-full overflow-x-auto">
              <TextField
                type="date"
                onChange={(e) =>
                  setEditPostsPayload({
                    ...editPostsPayload,
                    periodTo: e.target.value,
                  })
                }
                value={editPostsPayload.periodTo}
                fullWidth
                id="outlined-basic"
                label="Period To"
                variant="outlined"
              />
            </div>
          </ListItem>
        </List>
        <DialogActions>
          <Button
            onClick={() => {
              edit(editPostsPayload);
            }}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isDescOpen}
        onClose={() => setIsDescOpen(false)}>
        <DialogTitle>Description</DialogTitle>
        <DialogContent dividers>
          <div className="flex justify-center items-center">
            {p.description}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isGroupOpen}
        onClose={() => setIsGroupOpen(false)}>
        <DialogTitle>Groups</DialogTitle>
        <DialogContent dividers>
          <div className="flex justify-center items-center">
            {p.groups.map((e) => e.name).join(" ")}
          </div>
        </DialogContent>
      </Dialog>
      <tr key={i} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement value={50 * (currentPage - 1) + i + 1} />
        <TableElement value={p.brand?.name} />
        <TableElement style={{ whiteSpace: "pre-line" }} value={p.title} />

        <TableElement
          value={
            <>
              {p.url && p.type === "IMAGE" && (
                <img
                  loading="lazy"
                  className="w-32 aspect-square object-contain hover:scale-[2]"
                  src={
                    p.url.startsWith("https://") ? p.url : "https://" + p.url
                  }
                  alt="post"
                />
              )}
            </>
          }
        />

        <TableElement
          value={
            <>
              <span>{formatDate(p.periodFrom)}</span>
              <br />
              <span>{formatDate(p.periodTo)}</span>
            </>
          }
          type="element"
        />

        <TableElement value={p.postType.replace("_", " ")} />
        <TableElement value={p.category?.replace("_", " ")} />
        <TableElement
          value={
            <>
              <Button
                onDoubleClick={() => {
                  edit({ ...p, isActive: !p.isActive });
                }}>
                <Tooltip title={"Double click to change."} arrow>
                  <span
                    className={
                      p.isActive
                        ? " text-emerald-500 font-bold"
                        : "text-red-500 font-bold "
                    }>
                    ACTIVE
                  </span>
                </Tooltip>
              </Button>
              <Button
                onDoubleClick={() => {
                  edit({ ...p, isShareable: !p.isShareable });
                }}>
                <Tooltip title={"Double click to change."} arrow>
                  <span
                    className={
                      p.isShareable
                        ? " text-emerald-500 font-bold"
                        : "text-red-500 font-bold "
                    }>
                    SHAREABLE
                  </span>
                </Tooltip>
              </Button>
              <p
                className={
                  p.isDeleted
                    ? " text-emerald-500 font-bold mb-2"
                    : "text-red-500 font-bold mb-2"
                }>
                DELETED
              </p>
            </>
          }
          type="element"
        />
        <TableElement
          value={
            p.description && (
              <Button
                onClick={() => setIsDescOpen(true)}
                variant="text"
                aria-describedby="description-popover">
                Show
              </Button>
            )
          }
        />
        <TableElement
          value={
            <>
              <Button
                onClick={() => setIsGroupOpen(true)}
                variant="text"
                aria-describedby="groups-popover">
                Show
              </Button>
            </>
          }
        />
        <TableElement
          value={formatDateTimeComponent(p.createdOn)}
          type="element"
        />
        <TableElement value={p.type} />

        <TableElement
          value={
            <span
              className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
              onClick={() => onEditClick(p)}>
              EDIT
            </span>
          }
        />
      </tr>
    </>
  );
};
