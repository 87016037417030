import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

//{ brand, periodFrom, periodTo }
export default function MatAutoComplete({
  optionLabel,
  defaultValue,
  disabled = false,
  key,
  label,
  getData,
  options,
  setOptions,
  setValue,
  isMultiple,
}) {
  // const [inputValue, setInputValue] = useState('');
  const handleAutocompleteChange = (event, newValue) => {
    setValue(newValue);
  };
  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
      // setInputValue(value);
    } else {
      // setOptions([]);
    }
  };

  return (
    <div className="block w-full overflow-x-auto">
      <Autocomplete
        disabled={disabled}
        // inputValue={inputValue}
        id={key}
        value={key}
        defaultValue={defaultValue}
        multiple={isMultiple}
        getOptionLabel={(option, value) =>
          typeof option === "string"
            ? option
            : option.state && option.state.name
            ? option.name + " - " + option.state.name
            : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="No Data"
        fullWidth={true}
        options={options}
        onInputChange={onInputChange}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            id={key}
            value={key}
            {...params}
            label={label}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
