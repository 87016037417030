import React, { useEffect, useState } from "react";
import axios from "axios";
import { SchemesApi } from "apis/SchemesApi";
import { BrandsApi } from "apis/BrandsApi";
import { GroupsApi } from "apis/GroupsApi";
import { PriceDropApi } from "apis/PriceDropApi";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router";
import PriceDropTable from "components/Tables/PriceDropTable";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

export default function PriceDrop() {
  const navigate = useNavigate();
  const date = new Date();
  // const day = date.getDay();
  const [month, setMonth] = useState(date.getMonth() + 1);
  const [groups, setGroups] = useState([]);
  const months = [
    {
      name: "January",
      value: 1,
    },
    {
      name: "February",
      value: 2,
    },
    {
      name: "March",
      value: 3,
    },
    {
      name: "April",
      value: 4,
    },
    {
      name: "May",
      value: 5,
    },
    {
      name: "June",
      value: 6,
    },
    {
      name: "July",
      value: 7,
    },
    {
      name: "August",
      value: 8,
    },
    {
      name: "September",
      value: 9,
    },
    {
      name: "October",
      value: 10,
    },
    {
      name: "November",
      value: 11,
    },
    {
      name: "December",
      value: 12,
    },
  ];
  const [year, setYear] = useState(date.getFullYear());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");

  useEffect(() => {
    getDrops(selectedBrand.id, 0);
  }, [year, month, selectedBrand, groups]);

  const color = "light";
  const [brands, setBrands] = useState([]);

  const [selectedBrandForAdd, setSelectedBrandForAdd] = useState("");
  const [open, setOpen] = React.useState(false);
  const [drops, setDrops] = useState([""]);
  const [isEditingDrop, setIsEditingDrop] = useState(false);
  const [addDropPayload, setAddDropPayload] = useState({
    id: "",
    oldPrice: {
      mrp: 0,
      mop: 0,
      dp: 0,
      nlc: 0,
      remark: "",
    },
    newPrice: {
      mrp: 0,
      mop: 0,
      dp: 0,
      nlc: 0,
      remark: "",
    },
    diff: {
      mrp: 0,
      mop: 0,
      dp: 0,
      nlc: 0,
      remark: "",
    },
    changeDate: "2023-09-30",
    year: year,
    month: month,
    type: "INCREASED",
    product: {
      id: "",
      primaryName: "",
      secondaryName: "",
    },
    variant: {
      variantType: "",
      value: "",
    },
    brand: {
      id: "",
      name: "",
      image: "",
    },
    isActive: true,
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios.defaults.headers.common["x-api-key"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd";
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token");
    getBrands();
  }, []);

  const getBrands = () => {
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 20,
    })
      .then((res) => {
        setBrands(res.data.data.filter((d) => d.isActive));
        const brandsArray = res.data.data.filter((d) => d.isActive);
        setSelectedBrand(brandsArray[0]);
        getDrops(res.data.data[0].id, 0);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getDrops(selectedBrand.id, page - 1);
    return currentPage;
  };
  const getDrops = (id, page) => {
    console.log(groups.map((e) => e.id).join(","));
    PriceDropApi.GetByFilters({
      page: page,
      size: 25,
      brandId: id,
      year: year,
      month: month,
      userGroups: groups.length > 0 ? groups.map((e) => e.id).join(",") : null,
    })
      .then((res) => {
        setDrops(res.data.data);
        setTotalElements(res.data.totalElements);
        setTotalPages(res.data.pages);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  const handleChange = (event) => {
    setSelectedBrand(event.target.value);

    //console.log(event.target.value);
  };

  const onBrandSelect = (event) => {
    const brand = event.target.value;
    setSelectedBrandForAdd(brand);
    setAddDropPayload({
      ...addDropPayload,
      brandId: brand.id,
      brandName: brand.name,
    });
  };

  const addScheme = () => {
    setOpen(true);
  };
  const schemeEditClicked = (row) => {
    setOpen(true);
    setIsEditingDrop(true);
    setAddDropPayload({
      ...addDropPayload,
      id: row.id,
      name: row.name,
      brandId: row.brandId,
      brandName: row.brandName,
      imageUrl: row.imageUrl ? row.imageUrl : "",
      amount: row.amount,
      periodFrom: row.periodFrom,
      periodTo: row.periodTo,
      remark: row.remark ? row.remark : "",
      displayOrder: row.displayOrder,
      isActive: row.isActive ? true : false,
    });
  };

  const edit = () => {
    SchemesApi.UpdateScheme(addDropPayload)
      .then((res) => {
        setOpen(false);
        setIsEditingDrop(false);
        getDrops(selectedBrand.id, 0);
        toast("success", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getSelectedGroups = (data) => {
    setGroups(data);
  };
  const [groupOptions, setGroupOptions] = useState([]);
  const getGroupData = (searchTerm) => {
    GroupsApi.GetGroupsListBySearch({
      page: 0,
      size: 10,
      keyword: searchTerm,
    }).then((res) => {
      const filter = res.data.data.filter((ele) => ele.isActive);
      const updatedOptions = filter.map((p) => {
        return {
          name: p.name,
          id: p.id,
        };
      });
      setGroupOptions(updatedOptions);
    });
  };
  const save = () => {
    // SchemesApi.AddScheme(addDropPayload)
    //   .then((res) => {
    //     getDrops(selectedBrandForAdd.id, 0);
    //     setSelectedBrand(selectedBrandForAdd);
    //     setOpen(false);
    //     toast("Success", { type: "success" });
    //   })
    //   .catch((err) => {
    //     if (err?.response?.data) {
    //       toast(err.response.status + " " + err?.response?.data?.message, {
    //         type: "error", position: toast.POSITION.TOP_CENTER , autoClose: 1000
    //       });
    //     } else {
    //       toast(err?.response?.data?.message, { type: "error", position: toast.POSITION.TOP_CENTER , autoClose: 1000 });
    //     }
    //     if (err.response.status === 401) {
    //       localStorage.clear();
    //       navigate("/");
    //     }
    //   });
    // setOpen(false);
  };
  const handleChangeMonth = (e) => {
    setMonth(e.target.value);
  };

  const formatDate = (day, month, year) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day} ${monthNames[month]} ${year}`;
  };

  const getDayMonthYear = (d) => {
    const date = new Date(d);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return [day, month, year];
  };

  const getDate = (date) => {
    const data = getDayMonthYear(date);
    const formattedDate = formatDate(data[0], data[1], data[2]);
    return formattedDate;
  };

  return (
    <>
      <div
        className={
          "relative flex flex-row align-center min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBrand}
                label="Brand"
                onChange={handleChange}>
                {brands.map((b, i) => (
                  <MenuItem key={i} value={b}>
                    {b.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ marginTop: "15px" }}>
              <MatAutoComplete
                label={"Search by Group name"}
                getData={getGroupData}
                options={groupOptions}
                setOptions={setGroupOptions}
                setValue={getSelectedGroups}
                isMultiple={true}
              />
            </div>
            <div style={{ marginTop: "15px" }}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={month}
                      label="month"
                      onChange={handleChangeMonth}>
                      {months.map((m) => {
                        return <MenuItem value={m.value}>{m.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <TextField
                    onChange={(e) => {
                      setYear(e.target.value);
                    }}
                    value={year}
                    fullWidth
                    id="outlined-basic"
                    label="Year"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>

        {/* <div className="p-4">
          <button
            onClick={addScheme}
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            <span className="mr-2">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            Add Drop
          </button>
        </div> */}
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="w-full flex justify-end p-2 text-sky-500">
            Total: {totalElements}
          </div>
          <div className="text-center p-2 mb-2">
            <div className="">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Created on" />
                <TableHeader heading="Old Price" />
                <TableHeader heading="New Price" />
                <TableHeader heading="Difference" />
                <TableHeader heading="Type" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {drops?.map((s, index, arr) => {
                return (
                  <tr
                    key={index}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={index + 1} />
                    <TableElement
                      value={
                        <>
                          {s?.product?.primaryName}
                          <br />
                          {s?.product?.secondaryName}
                        </>
                      }
                    />
                    <TableElement value={getDate(s.createdOn)} />
                    <TableElement
                      value={
                        <>
                          DP :{s.oldPrice?.dp}
                          <br />
                          MOP :{s.oldPrice?.mop}
                        </>
                      }
                    />
                    <TableElement
                      value={
                        <>
                          DP :{s.newPrice?.dp}
                          <br />
                          MOP :{s.newPrice?.mop}
                        </>
                      }
                    />
                    <TableElement
                      value={
                        <>
                          DP :{s.diff?.dp}
                          <br />
                          MOP :{s.diff?.mop}
                        </>
                      }
                    />
                    <TableElement
                      value={
                        s.type === "INCREASED" ? (
                          <p className="text-red-500">{s.type}</p>
                        ) : (
                          <p className="text-emerald-500">{s.type}</p>
                        )
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Scheme add / edit */}
      <div>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add / Edit Price Drop
              </Typography>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Brand
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedBrandForAdd}
                      label="Brand"
                      onChange={onBrandSelect}>
                      {brands.map((b, i) => (
                        <MenuItem key={i} value={b}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </ListItem>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      name: e.target.value,
                    })
                  }
                  value={addDropPayload.name}
                  fullWidth
                  id="outlined-basic"
                  label="Scheme Name"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      imageUrl: e.target.value,
                    })
                  }
                  value={addDropPayload.imageUrl}
                  fullWidth
                  id="outlined-basic"
                  label="Image URL"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      amount: e.target.value,
                    })
                  }
                  value={addDropPayload.amount}
                  fullWidth
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  type="date"
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      periodFrom: e.target.value,
                    })
                  }
                  value={addDropPayload.periodFrom}
                  fullWidth
                  id="outlined-basic"
                  label="Start Date"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  type="date"
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      periodTo: e.target.value,
                    })
                  }
                  value={addDropPayload.periodTo}
                  fullWidth
                  id="outlined-basic"
                  label="End Date"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      remark: e.target.value,
                    })
                  }
                  value={addDropPayload.remark}
                  fullWidth
                  id="outlined-basic"
                  label="Remark"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      displayOrder: e.target.value,
                    })
                  }
                  value={addDropPayload.displayOrder}
                  fullWidth
                  id="outlined-basic"
                  label="Display Order"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setAddDropPayload({
                      ...addDropPayload,
                      isActive: e.target.value,
                    })
                  }
                  value={addDropPayload.isActive}
                  fullWidth
                  id="outlined-basic"
                  label="Is Active ?"
                  variant="outlined"
                />
              </div>
            </ListItem>
          </List>
          <DialogActions>
            {isEditingDrop ? (
              <Button onClick={edit}>Save Changes</Button>
            ) : (
              <Button onClick={save}>Save</Button>
            )}
          </DialogActions>
        </Dialog>
      </div>

      <ToastContainer />
    </>
  );
}
