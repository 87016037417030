import { sub_urls, onboarding_url, stage_url } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const FilesApi = {
  GetURL: async (payload) => {
    const response = await axios.get(onboarding_url + sub_urls.files.s3GetUrl, {
      params: {
        key: payload.key,
        type: payload.type,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  GetFiles: async (payload) => {
    const response = await axios.get(stage_url + sub_urls.files.getFiles, {
      params: {
        category: payload.category,
        skip: "" + payload.skip,
        limit: "" + payload.limit,
      },
      headers: Headers.getHeaders(),
    });
    return response;
  },
  UploadFiles: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.files.uploadFiles,
      {
        content: payload.content,
        name: payload.name,
        category: payload.category,
        folder: payload.folder,
        tags: payload.tags,
      },
      {
        params: {
          type: payload.type,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  DeleteFile: async (id, payload) => {
    const response = await axios.delete(
      stage_url + sub_urls.files.deleteFiles + "/" + id,
      {
        params: {
          category: payload.category,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
