import { onboarding_url, sub_urls } from "../api-urls";
import axios from "axios";

export const MasterApi = {
  GetRoles: async () => {
    const response = await axios.get(onboarding_url + sub_urls.master.roles, {
      headers: Headers.getHeaders(),
    });
    return response;
  },
  GetAddress: async (payload) => {
    const response = await axios.get(onboarding_url + sub_urls.master.address, {
      params: { pin: payload.pin },
      headers: Headers.getHeaders(),
    });
    return response;
  },
};
