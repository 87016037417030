import axios from "axios";
import { stage_url } from "../api-urls";
// import { getDukanHeaders } from "./Headers";

export async function getAllSubscriptions() {
  return await axios.get(
    `${stage_url}api-business-v3/users-subscriptions/subscriptions`,
    {
      headers: Headers.getDukanHeaders(),
    }
  );
}

export async function getAllSubscriptionsByFilters(payload) {
  return await axios.get(`${stage_url}api-business-v3/users-subscriptions`, {
    params: {
      page: payload.page,
      size: payload.size,
      startDate: payload.startDate,
      mobile: payload.mobile,
      subscriptionId: payload.subscriptionId,
      endDate: payload.endDate,
    },
    headers: Headers.getDukanHeaders(),
  });
}

export const createSubscriptionApi = (payload) => {
  return axios.post(
    `${stage_url}api-business-v3/users-subscriptions`,
    {},
    {
      params: {
        mobile: payload.mobile,
        subscriptionId: payload.subscriptionId,
        startDate: payload.startDate,
      },
      headers: Headers.getDukanHeaders(),
    }
  );
};

export const deleteSubscriptions = (id) => {
  return axios.delete(`${stage_url}api-business-v3/users-subscriptions/${id}`, {
    data: {},
    headers: Headers.getDukanHeaders(),
  });
};
