import React, { useEffect, useState } from "react";
import { ProductsApi } from "apis/ProductsApi";
import { BrandsApi } from "apis/BrandsApi";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import { StatesApi } from "apis/StatesApi";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddPriceTable from "components/Tables/AddPriceTable";
import { useNavigate } from "react-router";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};
export default function Products() {
  const navigate = useNavigate();
  const date = new Date();
  // const day = date.getDay();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchSize, setFetchSize] = useState(25);

  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;

  const color = "light";
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedPricesForEdit, setSelectedPricesForEdit] = useState([]);

  const [productData, setProductData] = useState();
  const [open, setOpen] = useState(false);
  const [AddData, setAddData] = useState(false);
  const [productAddPayload, setProductAddPayload] = useState({
    primaryName: null,
    secondaryName: null,
    specifications: {},
    image: null,
    video: null,
    description: null,
    brand: null,
    category: null,
    hsnCode: null,
    tacCode: [],
    categoryId: null,
    subCategory: null,
    subCategoryId: null,
    variants: [],
    brandId: null,
    brandSeriesId: null,
    brandSeries: null,
    isVerified: true,
    isActive: true,
    position: 0,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isVariantOpen, setIsVariantOpen] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [isPriceTableOpen, setIsPriceTableOpen] = useState(false);
  const [variantPayload, setVariantPayload] = useState({
    variantType: "",
    value: "",
  });
  const [colorPayload, setColorPayload] = useState({
    id: "",
    name: "",
    hexCode: "",
  });
  const [pricePayload, setPricePayload] = useState({
    mrp: "",
    mop: "",
    dp: "",
    nlc: null,
    remark: "",
    startDate: periodFrom,
    endDate: periodTo,
    groups: [""],
    isActive: true,
  });
  const [specification, setSpecification] = useState({
    key: null,
    value: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [isEditingVariant, setIsEditingVariant] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [states, setStates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [columns, setColumns] = useState([]);
  let priceArray = [];
  const [renderPrice, setRenderPrice] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState("");
  const [editIndividualPrice, setEditIndividualPrice] = useState(false);
  const [singleEditPayload, setSingleEditPayload] = useState({
    startDate: "",
    endDate: "",
    mop: "",
    dp: "",
    mrp: "",
    remark: "",
  });

  const [productRow, setProductRow] = useState(null);

  const basicSpecs = {
    PROCESSOR: "",
    FRONT_CAMERA: "",
    BACK_CAMERA: "",
    RAM: "",
    STORAGE: "",
    DISPLAY: "",
    BATTERY: "",
  };
  const openAddUpdateProduct = () => {
    setIsEdit(false);
    setSpecification({});
    setProductAddPayload({
      primaryName: null,
      specifications: basicSpecs,
      secondaryName: null,
      image: null,
      video: null,
      description: null,
      brand: null,
      category: null,
      hsnCode: null,
      tacCode: [],
      categoryId: null,
      subCategory: null,
      subCategoryId: null,
      variants: [],
      brandId: null,
      brandSeriesId: null,
      brandSeries: null,
      isVerified: true,
      isActive: true,
      position: 0,
    });
    setOpen(true);
  };

  const [openPricesTable, setOpenPricesTable] = useState(false);
  const [productPrices, setProductPrices] = useState(null);
  const handleClickOpenPrices = (prices) => {
    setProductPrices(prices);
    setOpenPricesTable(true);
  };
  const handleClosePrices = () => {
    console.log("inside clode price");
    setOpenPricesTable(false);
    setProductPrices([]);
  };

  useEffect(() => {
    getBrands();
    getCategories();
    getStates();
  }, []);

  useEffect(() => {
    getProducts(selectedBrand?.id, currentPage - 1, fetchSize, searchTerm);
  }, [currentPage, selectedBrand, fetchSize, searchTerm]);

  const getCategories = () => {
    ProductsApi.GetCategoryList({
      page: 0,
      size: 99,
    })
      .then((res) => {
        setCategoryList(res.data.data.filter((d) => d.isActive));
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getBrands = () => {
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        const filteredBrands = res.data.data.filter((d) => d.isActive);
        setBrands(filteredBrands);
        setSelectedBrand(filteredBrands[0]);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getProducts = (brandId, page, size, searchTerm) => {
    if (searchTerm !== undefined && searchTerm !== "") {
      ProductsApi.SearchProducts({
        page: page,
        size: size,
        keyword: searchTerm,
        brandId: brandId,
      })
        .then((res) => {
          setProducts(res.data.data);
          setTotalPages(res.data.pages);
          setTotalElements(res.data.totalElements);
        })
        .catch((err) => {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        });
    } else {
      ProductsApi.GetProductsListByFilter({
        page: page,
        size: size,
        brandId: brandId,
      })
        .then((res) => {
          setProducts(res.data.data);
          setTotalPages(res.data.pages);
          setTotalElements(res.data.totalElements);
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast(err.response?.status + " " + err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          } else {
            toast(err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
          if (err.response?.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };

  const handleChange = (event) => {
    setCurrentPage(1);
    setSearchTerm("");
    setSelectedBrand(event.target.value);
  };

  const save = () => {
    ProductsApi.AddProduct(productAddPayload)
      .then((res) => {
        toast("Product Created", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        setProductData(res.data);
        setCurrentPage(1);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const editProduct = (row) => {
    setIsEdit(true);
    setProductAddPayload({
      id: row.id,
      primaryName: row.primaryName,
      secondaryName: row.secondaryName,
      image: row.image,
      video: row.video,
      description: row.description,
      hsnCode: row.hsnCode,
      tacCode: row.tacCode?.length > 0 ? row.tacCode : undefined,
      categoryId: row.category.id,
      subCategoryId: row.subCategory.id,
      variants: row.variants,
      brandId: row.brand.id,
      brandSeriesId: row.brandSeries.id,
      isVerified: true,
      specifications:
        row.specifications && Object.keys(row.specifications).length !== 0
          ? row.specifications
          : basicSpecs,
      isActive: row.isActive,
      position: row.position,
    });
    setOpen(true);
  };

  const edit = () => {
    ProductsApi.EditProduct(productAddPayload.id, productAddPayload)
      .then(() => {
        toast("Product Updated", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setOpen(false);
        getProducts(null, 0);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const addEditVariant = (row) => {
    //console.log(row)
    setSelectedProduct(row);
    setIsVariantOpen(true);
  };
  const addEditColor = (row) => {
    //console.log(row)
    setSelectedProduct(row);
    setIsColorOpen(true);
  };

  const saveVariant = () => {
    //console.log(variantPayload);
    ProductsApi.AddVariant(productData.data.data.id, variantPayload)
      .then(() => {
        toast("Success", { type: "success" });
        setIsVariantOpen(false);
        getProducts(null, 0);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const saveColor = () => {
    ProductsApi.AddColor(productData.data.data.id, colorPayload)
      .then(() => {
        toast("Success", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setIsColorOpen(false);
        getProducts(null, 0);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const editColorClicked = (row, product) => {
    //  console.log(product);
    setSelectedProduct(product);
    setColorPayload({
      name: row.name,
      hexCode: row.hexCode,
      id: row.id,
    });
    setIsEditingColor(true);
    setIsColorOpen(true);
  };
  const editVariantClicked = (row, product) => {
    // console.log(row);
    setSelectedProduct(product);
    setVariantPayload({
      variantType: row.variantType,
      value: row.value,
      id: row.id,
    });
    setIsEditingVariant(true);
    setIsVariantOpen(true);
  };

  const editColor = () => {
    ProductsApi.EditColor(selectedProduct.id, colorPayload.id, colorPayload)
      .then(() => {
        toast("Success", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setIsColorOpen(false);
        setIsEditingColor(false);
        getProducts(null, 0);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };
  const editVariant = () => {
    ProductsApi.EditVariant(
      selectedProduct.id,
      variantPayload.id,
      variantPayload
    )
      .then(() => {
        toast("Success", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setIsVariantOpen(false);
        setIsEditingVariant(false);
        getProducts(null, 0);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };

  //? new add price
  const getStates = () => {
    StatesApi.GetStatesListByFilter()
      .then((res) => {
        // console.log(res.data.data)
        setStates(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setColumns([...states]);
      else {
        setColumns([]);
        setRenderPrice([]);
      }
      return !prev;
    });
  };
  const addEditPrice = (row) => {
    // console.log(row,states);
  };
  const addPrice = () => {
    console.log(selectedProduct, columns, "ssd");
    if (selectedProduct?.prices?.length > 0) {
      columns.map((c) => {
        const data = selectedProduct.prices.find(
          (p) => p.group.name === c.name
        );
        if (data) {
          c.priceId = data.id;
        }
      });
    }

    columns.map((col) => {
      col.startDate = pricePayload.startDate;
      col.endDate = pricePayload.endDate;
      col.mop = pricePayload.mop;
      col.dp = pricePayload.dp;
      col.mrp = pricePayload.mrp;
      priceArray.push(col);
    });
    setRenderPrice(priceArray);
  };

  useEffect(() => {
    addPrice();

    return () => {};
  }, [columns]);

  const savePrice = () => {
    // console.log(renderPrice);
    // console.log(renderPrice,selectedProduct);
    let payloadArray = [];
    renderPrice.map((price) => {
      let load = {
        id: price.priceId,
        startDate: price.startDate,
        endDate: price.endDate,
        mop: price.mop,
        dp: price.dp,
        mrp: price.mrp,
        state: {
          id: price.id,
          name: price.name,
        },
        isActive: price.isActive,
      };
      payloadArray.push(load);
      // console.log(load);
    });

    // console.log(payloadArray);
    if (payloadArray.length > 0) {
      payloadArray.map((price, i) => {
        if (price.id) {
          setTimeout(() => {
            ProductsApi.UpdatePrice(selectedProduct.id, price, price.id)
              .then((res) => {
                toast("New Price Updated", {
                  type: "success",
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 100,
                });
                setColumns([]);
                setRenderPrice([]);
              })
              .catch((err) => {
                toast(err?.response?.data?.message, {
                  type: "error",
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 1000,
                });
              });
          }, 150 * i);
        } else {
          setTimeout(() => {
            ProductsApi.AddPrice(selectedProduct.id, price)
              .then((res) => {
                toast("Price Created", {
                  type: "success",
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 100,
                });
                setColumns([]);
                setRenderPrice([]);
              })
              .catch((err) => {
                toast(err?.response?.data?.message, {
                  type: "error",
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 1000,
                });
              });
          }, 150 * i);
        }
      });
      setIsPriceOpen(false);
    }
  };

  const saveEditedPrice = () => {
    ProductsApi.UpdateCurrentPrice(
      selectedProduct.id,
      singleEditPayload,
      selectedPrice.id
    )
      .then((res) => {
        toast(res.message, {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setEditIndividualPrice(false);
        getProducts(null, currentPage - 1);
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  };
  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    return currentPage;
  };

  const options = ["Edit Product" /*"Variant +"*/, , "Price +", "Color +"];
  const [showPrice, setShowPrice] = React.useState(false);
  const [showColor, setShowColor] = React.useState(false);
  const [showVariant, setShowVariant] = React.useState(false);
  // const edit = () => {
  //   onEditClick(row);
  // };
  // const variant = () => {
  //   onVariantClick(row);
  // };
  // const colour = () => {
  //   onColorClick(row);
  // };
  // const price = () => {
  //   onPriceClick(row);
  // };

  // const editPrice = (p) => {
  //   pricesEditClick(p, row);
  // };
  // const editColor = (c) => {
  //   colorsEditClick(c, row);
  // };
  // const editVariant = (v) => {
  //   variantsEditClick(v, row);
  // };
  const [openEditPop, setOpenEditPop] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handlePriceClick = () => {
    setShowPrice(true);
  };
  const handleColorClick = () => {
    setShowColor(true);
  };
  const handleVariantClick = () => {
    setShowVariant(true);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseEditPop = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const [anchorElPrices, setAnchorElPrices] = React.useState(null);
  const [idPrices, setIdPrices] = React.useState(null);
  const [anchorElColors, setAnchorElColors] = React.useState(null);
  const [idColors, setIdColors] = React.useState(null);
  const [anchorElVariants, setAnchorElVariants] = React.useState(null);
  const [idVariants, setIdVariants] = React.useState(null);

  const handleClickShowPrices = (event) => {
    setAnchorElPrices(event.currentTarget);
    setIdPrices("prices-popover");
  };

  const handleClickShowColors = (event) => {
    setAnchorElColors(event.currentTarget);
    setIdColors("colors-popover");
  };

  const handleClickShowVariants = (event) => {
    setAnchorElVariants(event.currentTarget);
    setIdVariants("variants-popover");
  };

  const handleCloseShowPrices = () => {
    setAnchorElPrices(null);
  };

  const handleCloseShowColors = () => {
    setAnchorElColors(null);
  };

  const handleCloseShowVariants = () => {
    setAnchorElVariants(null);
  };

  const openShowPrices = Boolean(anchorElPrices);
  const openShowColors = Boolean(anchorElColors);
  const openShowVariants = Boolean(anchorElVariants);
  return (
    <>
      <PriceTable
        editIndividualPrice={editIndividualPrice}
        product={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setSelectedPrice={setSelectedPrice}
        setSingleEditPayload={setSingleEditPayload}
        setEditIndividualPrice={setEditIndividualPrice}
        isPriceTableOpen={isPriceTableOpen}
        setIsPriceTableOpen={setIsPriceTableOpen}
      />
      <div
        className={
          "relative flex flex-row align-center min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Brand</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBrand ? selectedBrand : ""}
                label="Brand"
                onChange={handleChange}>
                {brands.map((b, i) => (
                  <MenuItem key={i} value={b}>
                    {b.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="p-4">
          <button
            onClick={openAddUpdateProduct}
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            <span className="mr-2">
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            Add Product
          </button>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="mb-3 pt-0">
            <input
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
              type="text"
              placeholder="Search Model by name"
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white  font-medium rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
            />
          </div>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <div className="m-2">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={fetchSize}
                  label="Size"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setFetchSize(e.target.value);
                  }}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
              />
              <div className="flex items-center">
                <span className="text-sm font-medium align-baseline">
                  Total Products:- {totalElements}
                </span>
              </div>
            </Stack>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Image" />
                <TableHeader heading="Name" />
                <TableHeader heading="Prices" />
                <TableHeader heading="Active" />
                <TableHeader heading="Video" />
                {/* <TableHeader heading="Specifications"/> */}
                <TableHeader heading="Position" />
                <TableHeader heading="Category" />
                <TableHeader heading="Sub Category" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {products.map((p, index) => {
                return (
                  <tr
                    key={index}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement
                      value={
                        currentPage && fetchSize
                          ? (currentPage - 1) * fetchSize + (index + 1)
                          : index + 1
                      }
                    />
                    <TableElement
                      value={
                        p.image === null || " " ? (
                          <i className="fa-regular fa-image fa-xl"></i>
                        ) : (
                          <img
                            alt="product"
                            src={p.image}
                            height="100px"
                            className="h-12 w-12 bg-white border-4"
                          />
                        )
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <>
                          {p.primaryName}
                          <br />
                          {p.secondaryName}
                        </>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <>
                          <Button
                            variant="text"
                            onClick={(event) => {
                              console.log("Clicked");
                              setSelectedProduct(p);
                              setOpenPricesTable(true);
                              setIsPriceTableOpen(true);
                            }}>
                            {`Show - ${p?.prices?.length} `}
                          </Button>
                        </>
                      }
                      type="element"
                    />
                    <TableElement value={p.isActive} type="boolean" />
                    <TableElement
                      value={
                        p.video === null || " " ? (
                          <></>
                        ) : (
                          <a href={p.video} target="_blank" rel="noreferrer">
                            <i className="fa-xl fa-solid fa-circle-play"></i>
                          </a>
                        )
                      }
                      type="element"
                    />
                    {/* <TableElement value={p?.specifications}/> */}
                    <TableElement value={p.position} />
                    <TableElement value={p.category?.name} />
                    <TableElement value={p.subCategory?.name} />
                    <TableElement
                      value={
                        <div div className="flex flex-col">
                          <div
                            className="m-1 text-blue-300 hover:text-blue-700 cursor-pointer"
                            onClick={(e) => editProduct(p)}>
                            EDIT PRODUCT
                          </div>
                          <div
                            className="m-1 text-blue-300 hover:text-blue-700 cursor-pointer"
                            onClick={(e) => {
                              setSelectedProduct(p);
                              setIsPriceOpen(true);
                            }}>
                            PRICE +
                          </div>
                          <div
                            className="m-1 text-blue-300 hover:text-blue-700 cursor-pointer"
                            onClick={(e) => addEditColor(p)}>
                            COLOR +
                          </div>
                        </div>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* POPOVER EDIT PRODUCTS  */}

      {/* Products Add / Update Dialog */}
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {isEdit
                  ? "Edit Product - " + productAddPayload.primaryName
                  : "Add Product"}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  sx={{ m: 2 }}
                  label="Primary Name"
                  variant="standard"
                  value={productAddPayload.primaryName}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      primaryName: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="Secondary Name"
                  variant="standard"
                  value={productAddPayload.secondaryName}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      secondaryName: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="Description"
                  variant="standard"
                  value={productAddPayload.description}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      description: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ m: 2, minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    variant="standard"
                    value={productAddPayload.brandId}
                    label="Brand"
                    onChange={(e) => {
                      let brand = brands.filter((b) => b.id === e.target.value);
                      setProductAddPayload({
                        ...productAddPayload,
                        brandId: brand[0]?.id,
                        brand: e.target.value,
                        category: brand[0]?.category,
                        categoryId: brand[0]?.category.id,
                      });
                    }}>
                    {brands.map((b, i) => (
                      <MenuItem key={i} value={b.id}>
                        {b.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ m: 2, minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Brand Series
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    variant="standard"
                    value={productAddPayload.brandSeriesId}
                    label="Brand"
                    onChange={(e) => {
                      setProductAddPayload({
                        ...productAddPayload,
                        brandSeriesId: e.target.value,
                      });
                    }}>
                    {brands
                      .filter((b) => b.id === productAddPayload.brandId)[0]
                      ?.series?.map((bs, i) => {
                        if (bs.name) {
                          return (
                            <MenuItem key={i} value={bs.id}>
                              {bs.name}
                            </MenuItem>
                          );
                        } else return null;
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ m: 2, minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    {productAddPayload.brand ? "" : "Category"}
                  </InputLabel>
                  <Select
                    disabled
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    variant="standard"
                    value={productAddPayload.categoryId}
                    label="Category">
                    {categoryList.map((c, i) => (
                      <MenuItem key={i} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ m: 2, minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Sub Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    variant="standard"
                    value={productAddPayload.subCategoryId}
                    label=" Sub Category"
                    onChange={(e) => {
                      setProductAddPayload({
                        ...productAddPayload,
                        subCategoryId: e.target.value,
                      });
                    }}>
                    {categoryList
                      .filter((c) => c.id === productAddPayload.categoryId)[0]
                      ?.subcategories?.map((c, i) => {
                        if (c.name) {
                          return (
                            <MenuItem key={i} value={c.id}>
                              {c.name}
                            </MenuItem>
                          );
                        } else return null;
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="standard" sx={{ m: 2, minWidth: "100%" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Active
                  </FormLabel>
                  <RadioGroup
                    value={productAddPayload.isActive}
                    row
                    onChange={(e) => {
                      setProductAddPayload({
                        ...productAddPayload,
                        isActive: e.target.value,
                      });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isActive">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="Image URL"
                  variant="standard"
                  value={productAddPayload.image}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      image: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="Video URL"
                  variant="standard"
                  value={productAddPayload.video}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      video: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="HSN Code"
                  variant="standard"
                  value={productAddPayload.hsnCode}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      hsnCode: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="TAC Code"
                  variant="standard"
                  value={
                    productAddPayload?.tacCode?.length > 0
                      ? productAddPayload?.tacCode[0]
                      : undefined
                  }
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      tacCode: [].push(e.target.value),
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  type="number"
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="Position"
                  variant="standard"
                  value={productAddPayload.position}
                  onChange={(e) => {
                    setProductAddPayload({
                      ...productAddPayload,
                      position: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="  flex flex-col h-full items-center justify-center font-medium">
                  Add Specifications Here :-
                  <TextField
                    InputLabelProps={{
                      shrink: specification?.key?.length > 0,
                    }}
                    disabled={specification?.key in basicSpecs}
                    id="standard-requried"
                    sx={{ m: 2 }}
                    label={"Name"}
                    value={specification?.key}
                    variant="standard"
                    onChange={(e) => {
                      setSpecification({
                        ...specification,
                        key: e.target.value,
                      });
                    }}
                  />
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      if (specification.key) {
                        setProductAddPayload({
                          ...productAddPayload,
                          specifications: {
                            ...productAddPayload.specifications,
                            ["" + specification.key.trim().replace(" ", "_")]:
                              specification.value,
                          },
                        });
                        setSpecification({ key: "", value: "" });
                      }
                    }}>
                    Add
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                {Object.entries(
                  productAddPayload.specifications
                    ? productAddPayload.specifications
                    : {}
                ).map(([key, value], i) => (
                  <div key={key} className=" flex justify-between m-1">
                    <TextField
                      InputLabelProps={{
                        shrink: key?.length > 0,
                      }}
                      id="standard-requried"
                      sx={{ m: 1 }}
                      disabled
                      label={key ? "." : "Specification Name"}
                      variant="standard"
                      value={key.replace("_", " ")}
                      fullWidth
                    />
                    <TextField
                      id="standard-requried"
                      sx={{ m: 1 }}
                      label="Value"
                      variant="standard"
                      value={value}
                      onChange={(e) => {
                        setProductAddPayload({
                          ...productAddPayload,
                          specifications: {
                            ...productAddPayload.specifications,
                            [key]: e.target.value,
                          },
                        });
                      }}
                      fullWidth
                    />

                    {/* <div className="flex min-w-[80%] ">
                      <span className="font-bold">{`${key.replace(
                        "_",
                        " "
                      )} :-   `}</span>{" "}
                      <span className="mx-1 font-medium">{value}</span>
                    </div> */}

                    <DeleteIcon
                      onClick={() => {
                        if (!(key in basicSpecs)) {
                          setSpecification({
                            key: "",
                            value: "",
                          });
                          const spec = productAddPayload.specifications;
                          delete spec[key];
                          setProductAddPayload({
                            ...productAddPayload,
                            specifications: spec,
                          });
                        }
                      }}
                      sx={{
                        m: 2,
                        fontSize: "2rem",
                        cursor: key in basicSpecs ? "not-allowed" : "pointer",
                        color: key in basicSpecs ? "grey" : "red",
                      }}
                    />
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} md={4}></Grid>

              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <div className="text-center">
                  <span>
                    {isEdit ? (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        sx={{ m: 2 }}
                        onClick={edit}>
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        sx={{ m: 2 }}
                        onClick={save}>
                        Create Product
                      </Button>
                    )}
                  </span>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>

      {/* variant */}
      <div>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={isVariantOpen}
          onClose={() => setIsVariantOpen(false)}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsVariantOpen(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add / Edit Variant
              </Typography>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setVariantPayload({
                      ...variantPayload,
                      variantType: e.target.value,
                    })
                  }
                  value={variantPayload.variantType}
                  fullWidth
                  id="outlined-basic"
                  label="Type"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setVariantPayload({
                      ...variantPayload,
                      value: e.target.value,
                    })
                  }
                  value={variantPayload.value}
                  fullWidth
                  id="outlined-basic"
                  label="Value"
                  variant="outlined"
                />
              </div>
            </ListItem>
          </List>
          <DialogActions>
            {isEditingVariant ? (
              <Button onClick={editVariant}>Save Changes</Button>
            ) : (
              <Button onClick={saveVariant}>Save</Button>
            )}
          </DialogActions>
        </Dialog>
      </div>

      {/* color */}
      <div>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={isColorOpen}
          onClose={() => setIsColorOpen(false)}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsColorOpen(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add / Edit Color
              </Typography>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setColorPayload({
                      ...colorPayload,
                      name: e.target.value,
                    })
                  }
                  value={colorPayload.name}
                  fullWidth
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                />
              </div>
            </ListItem>
            <Divider />
            <ListItem className="mt-4">
              <div className="block w-full overflow-x-auto">
                <TextField
                  onChange={(e) =>
                    setColorPayload({
                      ...colorPayload,
                      hexCode: e.target.value,
                    })
                  }
                  value={colorPayload.hexCode}
                  fullWidth
                  id="outlined-basic"
                  label="Hex code"
                  variant="outlined"
                />
              </div>
            </ListItem>
          </List>
          <DialogActions>
            {isEditingColor ? (
              <Button onClick={editColor}>Save Changes</Button>
            ) : (
              <Button onClick={saveColor}>Save</Button>
            )}
          </DialogActions>
        </Dialog>
      </div>

      {/* price */}
      <div>
        <Dialog
          fullScreen
          open={isPriceOpen}
          onClose={() => setIsPriceOpen(false)}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsPriceOpen(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Add Price -
                {` ${selectedProduct?.primaryName} ${selectedProduct?.secondaryName}`}
              </Typography>
              <Button autoFocus color="inherit" onClick={savePrice}>
                Save
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="flex justify-evenly">
                  <TextField
                    id="standard-requried"
                    required
                    type="number"
                    sx={{ m: 2 }}
                    label="DP"
                    variant="standard"
                    value={pricePayload.dp}
                    onChange={(e) => {
                      setPricePayload({
                        ...pricePayload,
                        dp: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    id="standard-requried"
                    type="number"
                    required
                    sx={{ m: 2 }}
                    label="MOP"
                    variant="standard"
                    value={pricePayload.mop}
                    onChange={(e) => {
                      setPricePayload({
                        ...pricePayload,
                        mop: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    id="standard-requried"
                    required
                    type="number"
                    sx={{ m: 2 }}
                    label="MRP"
                    variant="standard"
                    value={pricePayload.mrp}
                    onChange={(e) => {
                      setPricePayload({
                        ...pricePayload,
                        mrp: e.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="flex justify-evenly">
                  <TextField
                    id="standard-requried"
                    required
                    type="date"
                    sx={{ m: 2 }}
                    label="Start Date"
                    variant="standard"
                    value={pricePayload.startDate}
                    onChange={(e) => {
                      setPricePayload({
                        ...pricePayload,
                        startDate: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    id="standard-requried"
                    required
                    type="date"
                    sx={{ m: 2 }}
                    label="End Date"
                    variant="standard"
                    value={pricePayload.endDate}
                    onChange={(e) => {
                      setPricePayload({
                        ...pricePayload,
                        endDate: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    id="standard-requried"
                    sx={{ m: 2 }}
                    label="Remark"
                    variant="standard"
                    value={pricePayload.remark}
                    onChange={(e) => {
                      setPricePayload({
                        ...pricePayload,
                        remark: e.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="standard" sx={{ m: 2, minWidth: "100%" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Active
                  </FormLabel>
                  <RadioGroup
                    row
                    value={pricePayload.isActive}
                    onChange={(e) =>
                      setPricePayload({
                        ...pricePayload,
                        isActive: e.target.value,
                      })
                    }
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isActive">
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="block w-full overflow-x-auto">
                  {states ? (
                    <Autocomplete
                      variant="standard"
                      sx={{ m: 2 }}
                      multiple
                      disabled={
                        !pricePayload.dp ||
                        !pricePayload.mrp ||
                        !pricePayload.mop
                      }
                      options={states}
                      filterSelectedOptions
                      freeSolo={false}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={columns}
                      onChange={(_e, value, reason) => {
                        if (reason === "clear" || reason === "removeOption")
                          setSelectAll(false);
                        if (
                          reason === "selectOption" &&
                          value.length === states.length
                        )
                          setSelectAll(true);
                        setColumns(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          label={"Select States"}
                        />
                      )}
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()} // prevent blur
                              pl={1.5}
                              py={0.5}>
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault(); // prevent blur
                                  handleToggleSelectAll();
                                }}
                                label="Select all"
                                control={
                                  <Checkbox
                                    id="select-all-checkbox"
                                    checked={selectAll}
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>

            <Divider sx={{ m: 2 }} />
            <AddPriceTable
              setRenderPrice={setRenderPrice}
              renderPrice={renderPrice}
            />
          </DialogContent>
        </Dialog>
      </div>

      {/* Individual price */}
      <div>
        <Dialog
          // fullScreen
          open={editIndividualPrice}
          onClose={(e, r) => {
            if (r !== "backdropClick") {
              setEditIndividualPrice(false);
            }
          }}
          TransitionComponent={Transition}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setEditIndividualPrice(false)}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Price - {selectedProduct?.primaryName} -{" "}
                {selectedPrice?.group?.name}
              </Typography>
              <Button autoFocus color="inherit" onClick={saveEditedPrice}>
                Save
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="number"
                  sx={{ m: 2 }}
                  label="DP"
                  variant="standard"
                  onChange={(e) =>
                    setSingleEditPayload({
                      ...singleEditPayload,
                      dp: e.target.value,
                    })
                  }
                  value={singleEditPayload.dp}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  type="number"
                  required
                  sx={{ m: 2 }}
                  label="MOP"
                  variant="standard"
                  onChange={(e) =>
                    setSingleEditPayload({
                      ...singleEditPayload,
                      mop: e.target.value,
                    })
                  }
                  value={singleEditPayload.mop}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="number"
                  sx={{ m: 2 }}
                  label="MRP"
                  variant="standard"
                  onChange={(e) =>
                    setSingleEditPayload({
                      ...singleEditPayload,
                      mrp: e.target.value,
                    })
                  }
                  value={singleEditPayload.mrp}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="date"
                  sx={{ m: 2 }}
                  label="Start Date"
                  variant="standard"
                  onChange={(e) =>
                    setSingleEditPayload({
                      ...singleEditPayload,
                      startDate: e.target.value,
                    })
                  }
                  value={singleEditPayload.startDate}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  required
                  type="date"
                  sx={{ m: 2 }}
                  label="End Date"
                  variant="standard"
                  onChange={(e) =>
                    setSingleEditPayload({
                      ...singleEditPayload,
                      endDate: e.target.value,
                    })
                  }
                  value={singleEditPayload.endDate}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="standard-requried"
                  sx={{ m: 2 }}
                  label="Remark"
                  variant="standard"
                  onChange={(e) =>
                    setSingleEditPayload({
                      ...singleEditPayload,
                      remark: e.target.value,
                    })
                  }
                  value={singleEditPayload.remark}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

const PriceTable = ({
  product,
  setSelectedProduct,
  setSelectedPrice,
  setSingleEditPayload,
  editIndividualPrice,
  setEditIndividualPrice,
  isPriceTableOpen,
  setIsPriceTableOpen,
}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const editPriceClicked = (price, product) => {
    // console.log(product, price);
    setSelectedPrice(price);
    setSelectedProduct(product);
    setSingleEditPayload({
      dp: price.dp,
      mop: price.mop,
      mrp: price.mrp,
      startDate: price.startDate,
      endDate: price.endDate,
    });
    setEditIndividualPrice(true);
  };

  return product?.prices ? (
    <>
      <BootstrapDialog
        onClose={() => {
          if (!editIndividualPrice) {
            setSelectedProduct(null);
          }
          setIsPriceTableOpen(false);
        }}
        aria-labelledby="customized-dialog-title-prices"
        open={isPriceTableOpen}
        maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title-prices">
          Price Details - {product.primaryName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            if (!editIndividualPrice) {
              setSelectedProduct(null);
            }
            setIsPriceTableOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100 sticky">
                <tr className="divide-x divide-gray-200 ">
                  <TableHeader heading="" />
                  <TableHeader heading="Group Name" />
                  <TableHeader heading="DP" />
                  <TableHeader heading="MOP" />
                  <TableHeader heading="MRP" />
                  <TableHeader heading="Date" />
                  <TableHeader heading="" />
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {product.prices?.map((p, index) => {
                  return (
                    <tr
                      key={index}
                      className="divide-x divide-gray-200 hover:bg-slate-100">
                      <TableElement value={index + 1} />
                      <TableElement value={p.group?.name} />
                      <TableElement value={p.dp} />
                      <TableElement value={p.mop} />
                      <TableElement value={p.mrp} />
                      <TableElement
                        value={
                          <>
                            {p.startDate} - {p.endDate}
                          </>
                        }
                        type="element"
                      />
                      <TableElement
                        value={
                          <span
                            className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
                            onClick={() => editPriceClicked(p, product)}>
                            EDIT
                          </span>
                        }
                        type="element"
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  ) : (
    <></>
  );
};
