import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";
import Headers from "./Headers";

export const StatesApi = {
  GetStatesListByFilter: async () => {
    const response = await axios.get(
      stage_url + sub_urls.states.getStatesByFilter,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddState: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.states.baseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
