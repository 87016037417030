import { stage_url, sub_urls } from "../api-urls";
import axios from "axios";

export const BrandsApi = {
  GetBrandsListByFilter: async (payload) => {
    const response = await axios.get(
      stage_url + sub_urls.brands.getBrandsByFilter,
      {
        params: {
          page: payload.page,
          size: payload.size,
        },
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddBrand: async (payload) => {
    const response = await axios.post(
      stage_url + sub_urls.brands.brandsBaseUrl,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  EditBrand: async (id, payload) => {
    const response = await axios.put(
      stage_url + sub_urls.brands.brandsBaseUrl + "/" + id,
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
  AddSeries: async (id, payload) => {
    const response = await axios.post(
      stage_url + sub_urls.brands.brandsBaseUrl + "/" + id + "/series",
      payload,
      {
        headers: Headers.getHeaders(),
      }
    );
    return response;
  },
};
